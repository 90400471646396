import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { isLiinilaevad } from '../../utils/liinilaevadThemeUtils'
import { DetailsItem } from './DetailsItem'

const OverWrap = styled.div`
  @media (max-width: 600px) {
    overflow-x: auto;
  }
`

const Wrapper = styled.table`
  line-height: 32px;
  text-align: left;
  ${isLiinilaevad && 'font-family: SourceSansPro, Sans-Serif;'}

  ${({ collapsed }) =>
    collapsed &&
    `
    width: 100%;

    & > tr > td:first-child {
      text-align: right;
    }

    & > tr > td:last-child {
      text-align: left;
    }
  `}

  ${({ stretched }) =>
    stretched &&
    `
    width: 100%;

    & > tr > td:first-child {
      text-align: left;
    }

    & > tr > td:last-child {
      text-align: right;
    }
  `}
`

class Details extends PureComponent {
  static Item = DetailsItem

  render() {
    const { className, children, ...type } = this.props

    return (
      <OverWrap>
        <Wrapper className={className} {...type}>
          {children}
        </Wrapper>
      </OverWrap>
    )
  }
}

export { Details }
