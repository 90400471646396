export const VEHICLE_TYPE_BICYCLE = 'BICYCLE'
export const VEHICLE_TYPE_CAR = 'CAR'
export const VEHICLE_TYPE_TRAILER = 'TRAILER'
export const VEHICLE_TYPE_VEHICLE = 'VEHICLE'

export const ITEM_TYPE_PASSENGER = 'PASSENGER'
export const ITEM_TYPE_VEHICLE = 'VEHICLE'

export const PLATE_NUMBER_STATUS_VERIFIED = 'verified'
export const PLATE_NUMBER_STATUS_FAILED = 'failed'

export const SALE_CHANNEL = {
  CASH_DESK: 'CASH DESK',
  ONLINE: 'ONLINE',
  KIOSK: 'KIOSK',
  BACK_OFFICE: 'BACK OFFICE',
}
