import React, { PureComponent } from 'react'
import cn from 'classnames'
import s from './Panel.module.scss'

class Panel extends PureComponent {
  render() {
    const { children, className, row, noBorder, noWrap, style } = this.props
    const rootClassNames = cn(className, s.root, {
      [s.row]: row,
      [s.border]: !noBorder,
      [s.noWrap]: noWrap,
    })

    return (
      <div className={rootClassNames} style={style}>
        {children}
      </div>
    )
  }
}

export { Panel }
