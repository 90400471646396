import React, { Fragment, PureComponent } from 'react'
import styled from 'styled-components'
import { translate } from '../../modules/Common/Translator'
import messages from '../../consts/messages'
import { Alert } from './Alert'

const Content = styled.div``

const StyledAlert = styled(Alert)`
  & + ${Content} {
    &:not(:empty) {
      margin-top: 20px;
    }
  }
`

function withAlert(WrappedComponent) {
  return class withAlert extends PureComponent {
    render() {
      return (
        <Fragment>
          {this.renderAlert()}

          <Content>
            <WrappedComponent {...this.props} />
          </Content>
        </Fragment>
      )
    }

    renderAlert() {
      return [this.getErrorAlert(), this.getInfoAlert(), this.getSuccessAlert(), this.getWarningAlert()].find((x) => x)
    }

    getErrorAlert() {
      if (!this.props.error) {
        return undefined
      }

      return (
        <StyledAlert error>
          {this.props.error === 'FAILED_TO_ALLOCATE' ? translate(messages.customWeightFormError) : this.props.error}
        </StyledAlert>
      )
    }

    getInfoAlert() {
      if (!this.props.info) {
        return undefined
      }

      return <StyledAlert info>{this.props.info}</StyledAlert>
    }

    getSuccessAlert() {
      if (!this.props.success) {
        return undefined
      }

      return <StyledAlert success>{this.props.success}</StyledAlert>
    }

    getWarningAlert() {
      if (!this.props.warning) {
        return undefined
      }

      return <StyledAlert warning>{this.props.warning}</StyledAlert>
    }
  }
}

export { withAlert }
