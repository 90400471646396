import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'

const TableHeading = styled.td`
  color: ${(props) => props.color || '#bfc3c7'};
  padding-right: 10px;
  white-space: nowrap;

  @media (max-width: 840px) {
    display: block;
    white-space: normal;
  }
`

const MobileBlock = styled.tr`
  @media (max-width: 840px) {
    display: block;
  }
`

class DetailsItemView extends PureComponent {
  render() {
    const { children, intl, label, optionalLabel } = this.props

    return (
      <MobileBlock>
        <TableHeading color={this.props.color}>
          {optionalLabel ? optionalLabel.concat(':') : intl.formatMessage(label).concat(':')}
        </TableHeading>

        <MobileBlock>{children}</MobileBlock>
      </MobileBlock>
    )
  }
}

const DetailsItem = injectIntl(DetailsItemView)

export { DetailsItem }
