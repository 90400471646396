import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { pick, pathOr, isEmpty } from 'ramda'
import { UILayout, UIButton, UIText } from 'bora-material-ui'

import { translate } from '../modules/Common/Translator'
import { TextField } from './InputField'
import { IconBeforeField, RequiredLabel } from './uiComponents'
import messages from '../consts/messages'
import { required as validateRequired } from '../utils/validate'

class FileInput extends React.Component {
  static propTypes = {
    meta: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired,
    icon: PropTypes.string,
    text: PropTypes.string,
    required: PropTypes.bool,
    labelStyles: PropTypes.object,
    wrapperStyles: PropTypes.object,
    wrapperInputStyles: PropTypes.object,
    hideLabel: PropTypes.bool,
    wrapperLabelStyles: PropTypes.object,
    hideStar: PropTypes.bool,
    showPlus: PropTypes.bool,
    starStyles: PropTypes.object,
    readOnly: PropTypes.bool,
    accept: PropTypes.string,
    smallText: PropTypes.object,
  }

  static defaultProps = {
    required: false,
    hideLabel: false,
    text: '',
    icon: '',
    labelStyles: {},
    wrapperStyles: {},
    wrapperInputStyles: {},
    wrapperLabelStyles: {},
    hideStar: false,
    showPlus: false,
    starStyles: {},
    readOnly: false,
    accept: '',
    smallText: {},
  }

  static fileInputStyles = {
    position: 'absolute',
    width: 0,
    height: 0,
    opacity: 0,
  }

  static buttonStyles = {
    height: '44px',
    lineHeight: '44px',
  }

  static makeId = (name) => {
    let text = ''
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (let i = 0; i < 5; i += 1) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }

    return `${name}-${text}`
  }

  constructor(props, context) {
    super(props, context)
    this.state = {}
  }

  onFileSelect = async (e) => {
    const {
      input: { onChange },
    } = this.props
    const [file] = e.target.files
    if (file) {
      const meta = pick(['name', 'size'], file)
      const base64 = await this.getBase64(file)
      return onChange({ meta, base64 })
    }
    return onChange(null)
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  triggerBrowseFile = () => {
    this.input.click()
  }

  render() {
    const {
      icon,
      required,
      hideLabel,
      hideStar,
      showPlus,
      starStyles,
      text,
      accept,
      meta,
      labelStyles,
      wrapperStyles,
      wrapperLabelStyles,
      wrapperInputStyles,
      input,
      smallText,
      ...textFieldProps
    } = this.props

    const buttonText = translate(messages.fileField)
    const inputID = FileInput.makeId(input.name)
    const inputPadding = isEmpty(smallText) ? '16px 0 0 0' : '2px 0 0 0'

    return (
      <UILayout column width="100%" padding="30px 30px 0 0" {...wrapperStyles}>
        <RequiredLabel
          label={messages[this.props.input.name]}
          text={text}
          hideLabel={hideLabel}
          hideStar={hideStar}
          required={required}
          invalid={this.props.meta.invalid}
          wrapperLabelStyles={wrapperLabelStyles}
          labelStyles={labelStyles}
          starStyles={starStyles}
        />
        <UIText color="#464646" size="12px" align="left" translate={smallText} display-if={!isEmpty(smallText)} />
        <label htmlFor={inputID}>
          <UILayout position="relative" padding={inputPadding} {...wrapperInputStyles} center borderRadius="inherit">
            <IconBeforeField icon={icon} showPlus={showPlus} plusColor={wrapperInputStyles.plusColor} />
            <TextField
              {...textFieldProps}
              style={{ 'pointer-events': 'none' }}
              type="text"
              required={required}
              readOnly
              input={{ ...input, value: pathOr('', ['value', 'meta', 'name'], input) }}
              meta={meta}
            />
            <input
              id={inputID}
              ref={(el) => {
                this.input = el
              }}
              type="file"
              style={FileInput.fileInputStyles}
              onChange={this.onFileSelect}
              accept={accept}
            />
            <UIButton
              onClick={this.triggerBrowseFile}
              style={FileInput.buttonStyles}
              xs={{ width: 'auto' }}
              {...FileInput.buttonStyles}
            >
              {buttonText}
            </UIButton>
          </UILayout>
        </label>
      </UILayout>
    )
  }
}

const FileField = (props) => {
  const { name, label, validate, required, ...restProps } = props
  const fieldProps = { required }
  const validators = [...validate]

  if (required) {
    validators.push(validateRequired)
  }

  return (
    <Field name={name} label={label} component={FileInput} props={fieldProps} validate={validators} {...restProps} />
  )
}

FileField.propTypes = {
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string,
  required: PropTypes.bool,
  smallText: PropTypes.object,
}

FileField.defaultProps = {
  label: '',
  text: '',
  placeholder: '',
  required: false,
  validate: [],
  smallText: {},
}

export default FileField
