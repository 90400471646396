import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import InputMask from 'react-input-mask'
import { UITextField, UILayout, UIText, UIIcon } from 'bora-material-ui'

import { required as validateRequired } from '../utils/validate'

export const PromoCodeInput = (props) => {
  const {
    input: { value, onChange, onBlur, onFocus },
    inputWrapperStyles,
    wrapperStyles,
    label,
    labelStyles,
    icon,
    ...fieldStyles
  } = props

  return (
    <UILayout column width="100%" margin="0 15px 0 0" padding="30px 0 0 0" {...wrapperStyles} position="relative">
      <UILayout padding="0 0 2px 0">
        <UIText display-if={label} size="18px" color="black" weight="bold" align="left" text={label} {...labelStyles} />
      </UILayout>
      <UILayout padding="16px 0 0 0" center position="relative" {...inputWrapperStyles}>
        <UITextField
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          change={onChange}
          id="i18n.textField"
          hintText={null}
          type={props.type || 'text'}
          inputStyle={{ textTransform: 'uppercase' }}
          {...fieldStyles}
        >
          <InputMask value={value} placeholder={props.placeholder || '0000 - 000 - 000'} />
        </UITextField>
        <UILayout display-if={Boolean(icon)} width="40px" right="0px" position="absolute">
          <UIIcon position="absolute" type={icon} />
        </UILayout>
      </UILayout>
    </UILayout>
  )
}

PromoCodeInput.propTypes = {
  input: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  labelStyles: PropTypes.object,
  icon: PropTypes.string,
  fieldStyles: PropTypes.object,
  wrapperStyles: PropTypes.object,
  inputWrapperStyles: PropTypes.object,
  label: PropTypes.string,
}

PromoCodeInput.defaultProps = {
  labelStyles: {},
  fieldStyles: {},
  wrapperStyles: {},
  inputWrapperStyles: {},
  icon: '',
  label: '',
}

const PromoCodeField = ({ name, label, validate = [], required, placeholder, showIcon, value, ...props }) => (
  <Field
    name={name}
    id={name}
    type="text"
    props={{ label, showIcon, value }}
    component={PromoCodeInput}
    placeholder={placeholder}
    validate={required ? [validateRequired, ...validate] : validate}
    {...props}
  />
)

PromoCodeField.propTypes = {
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
}

PromoCodeField.defaultProps = {
  label: '',
  placeholder: '',
  required: false,
}

export default PromoCodeField
