import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { isLiinilaevad } from '../../utils/liinilaevadThemeUtils'

const Wrapper = styled.div`
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: #79909b;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  padding: 14px 20px;

  ${({ error }) =>
    error &&
    `
    background-color: #ffe8e7;
    border-color: #b42a2a;
  `}

  ${({ info }) =>
    info &&
    `
    background-color: #ebf4fa;
    border-color: #4266bd;
  `}

  ${({ success }) =>
    success &&
    `
    background-color: #eff8e9;
    border-color: #417505;
  `}

  ${({ warning }) =>
    warning &&
    `
    background-color: #fff6d8;
    border-color: ${isLiinilaevad ? '#A67400' : '#f38211'};
  `}
`

class Alert extends PureComponent {
  render() {
    const { children, className, ...type } = this.props

    return (
      <Wrapper className={className} {...type}>
        {children}
      </Wrapper>
    )
  }
}

export { Alert }
