import React from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIText } from 'bora-material-ui'
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton'
import { map, F, pathOr, toLower } from 'ramda'
import { getStyle } from '../utils/liinilaevadThemeUtils'

const baseRadioButtonStyles = {
  boxSizing: 'border-box',
  width: '20px',
  height: '20px',
  margin: '2px',
  backgroundColor: '#fbfbfb',
  border: 'solid 1px #dddddd',
  borderRadius: '50%',
}

const styles = {
  buttonGroupRoot: {
    width: '100%',
    padding: '0 10px 0 45px',
  },
  radioButton: {
    marginTop: 10,
  },
  labelStyle: {
    color: '404b50',
    fill: '404b50',
  },
  radioButtonIcon: {
    ...baseRadioButtonStyles,
  },
  radioButtonIconChecked: {
    ...baseRadioButtonStyles,
    border: getStyle('solid 5px #7ed321', 'solid 5px #0eb1bc'),
  },
}

const CheckedRadioButton = <div style={styles.radioButtonIconChecked} />
const UncheckedRadioButton = <div style={styles.radioButtonIcon} />

const OptionsRadioGroup = (props, context) => {
  const { name, label, options, activeValue, onChange, disabled } = props

  const T = pathOr(F, ['intl', 'formatMessage'], context)

  return (
    <UILayout column width="100%" position="relative">
      <UIText align="center" size="18px" color="#000000" textTransform="capitalize" text={label} />
      <UILayout padding="0 0 2px 0">
        <RadioButtonGroup
          name={name}
          defaultSelected={activeValue}
          onChange={(event, value) => onChange(value)}
          style={styles.buttonGroupRoot}
        >
          {map(
            ({ title, value, translation }) => (
              <RadioButton
                value={value}
                label={toLower((translation && T(translation)) || title)}
                style={styles.radioButton}
                labelStyle={styles.labelStyle}
                checkedIcon={CheckedRadioButton}
                uncheckedIcon={UncheckedRadioButton}
                disabled={disabled}
              />
            ),
            options
          )}
        </RadioButtonGroup>
      </UILayout>
    </UILayout>
  )
}

OptionsRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  activeValue: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

OptionsRadioGroup.defaultProps = {
  label: '',
  activeValue: '',
  disabled: false,
}

OptionsRadioGroup.contextTypes = {
  intl: PropTypes.any,
}

export default OptionsRadioGroup
