import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { UITicketParam } from 'bora-material-ui'

const TicketParamWrapper = (props) => {
  const {
    input: { value: properties, onChange },
    label,
    icon,
    expanded,
  } = props

  const changeHandler = (property, count, index) => {
    const prevCount = properties[index].count
    const countDiff = count - prevCount

    properties[index] = {
      ...property,
      count,
    }

    onChange(
      properties.map((item) => ({
        ...item,
        canAdd: property.canAdd - countDiff > 0 ? property.canAdd - countDiff : 0,
      }))
    )
  }

  return (
    <UITicketParam
      title={label}
      iconSrc={icon}
      expanded={expanded}
      properties={properties}
      display-if={properties.length > 0}
      changeProperty={changeHandler}
    />
  )
}

TicketParamWrapper.propTypes = {
  input: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
}

TicketParamWrapper.defaultProps = {
  expanded: false,
}

const TicketParamField = ({ name, label, icon, expanded }) => (
  <Field name={name} label={label} props={{ icon, expanded }} component={TicketParamWrapper} />
)

TicketParamField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
}

TicketParamField.defaultProps = {
  expanded: false,
}

export default TicketParamField
