import React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import { UIButton, UIIcon } from 'bora-material-ui'
import { Panel } from './Panel'
import messages from '../../consts/messages'
import { xsStyles } from '../../modules/Booking/forms/TicketsForm/components/SelectedTicketTypeRow'
import { getStyle } from '../../utils/liinilaevadThemeUtils'

const StyledPanel = styled(Panel)`
  position: relative;
  ${({ noPadding }) => noPadding && 'padding: 0 !important;'}
  ${({ newLineEdit }) =>
    newLineEdit &&
    `
      @media (max-width: 840px) {
        display: flex;
        flex-direction: column ;
      }
    `}
`

const EditButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  float: right;
  height: 24px;
  padding-bottom: 8px;

  @media (max-width: 840px) {
    float: none;
  }
`

const EditButtonText = styled.div`
  font-family: GinesoNormDem, Fjalla One, sans-serif;
  font-size: 18px;
  color: #79909b;
  padding-left: 5px;
  text-transform: uppercase;
`

const lockIconStyle = {
  height: '18px',
  width: '18px',
}

const pencilIconStyle = {
  fill: getStyle('#3387CC', '#0eb1bc'),
  height: '14px',
  width: '14px',
}

class EditablePanelView extends React.PureComponent {
  static defaultProps = {
    locked: false,
  }

  render() {
    const {
      children,
      intl,
      locked,
      onEdit: handleEdit,
      onRemove: handleRemove,
      showRemoveButton = false,
      ...rest
    } = this.props

    return (
      <StyledPanel {...rest}>
        <EditButton display-if={locked}>
          <UIIcon {...lockIconStyle} type="iconLock" />
        </EditButton>

        <EditButton display-if={!locked && handleEdit} onClick={handleEdit}>
          <UIIcon {...pencilIconStyle} type="iconPencil" />

          <EditButtonText
            style={getStyle({ color: '#3387CC', fontFamily: 'SourceSansPro, Sans-Serif', fontWeight: 700 })}
          >
            {intl.formatMessage(messages.edit)}
          </EditButtonText>
        </EditButton>

        <UIButton
          display-if={showRemoveButton && !locked}
          type="circle"
          background="#d0021b"
          width="30px"
          height="30px"
          xs={xsStyles.removeButton}
          onClick={handleRemove}
        >
          <UIIcon type="iconClose" />
        </UIButton>

        {children}
      </StyledPanel>
    )
  }
}

const EditablePanel = injectIntl(EditablePanelView)

export { EditablePanel }
