import { createAction } from 'redux-actions'
import * as Actions from '../consts/actions'

export { extendReservation } from '../services/reservation/actions/reservation'
export const removeNotify = (notifyIndex) => ({ type: Actions.REMOVE_NOTIFICATION, notifyIndex })

export const showModal = createAction(Actions.SHOW_MODAL)
export const setSelectedSailPackageIndex = createAction(Actions.SET_SELECTED_SAIL_PACKAGE_INDEX)
export const showNotify = createAction(Actions.SHOW_NOTIFICATION)

export const toggleDirection = createAction(Actions.TOGGLE_DIRECTION)
export const getDataForReservationUpdate = createAction(Actions.FETCH_DATA_FOR_RESERVATION_UPDATE)
export const goToPage = createAction(Actions.GO_TO_PAGE)
export const goToRefund = createAction(Actions.GO_TO_REFUND)
export const goToEditReservation = createAction(Actions.GO_TO_EDIT_RESERVATION)
export const backToPreviousPage = createAction(Actions.BACK_TO_PREVIOUS_PAGE)

export const setReservationCustomerInfo = createAction(Actions.SET_RESERVATION_CUSTOMER_INFO)
export const setReservationPassengerInfo = createAction(Actions.SET_RESERVATION_PASSENGER_INFO)

export const updateModalState = createAction(Actions.UPDATE_MODAL_STATE)

export const switchViewModeAction = createAction(Actions.SWITCH_VIEW_MODE)

export const addReturnRoute = createAction(Actions.ADD_RETURN_ROUTE)

export const userNeedToPayAction = createAction(Actions.USER_NEED_TO_PAY)

export const setViewMode = createAction(Actions.SET_VIEW_MODE)

export const showMessage = createAction(Actions.SHOW_MESSAGE)
export const updateEditableFields = createAction(Actions.UPDATE_EDITABLE_FIELDS)

export const setModalData = createAction(Actions.SET_MODAL_DATA)
export const setPassengerToEdit = createAction(Actions.SET_PASSENGER_TO_EDIT)
export const clearModalData = createAction(Actions.CLEAR_MODAL_DATA)
