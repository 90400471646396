import React, { PureComponent } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;

  & > * {
    flex-basis: 0;
    flex-grow: 1;
    padding-left: 20px;
    padding-right: 20px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`

class Row extends PureComponent {
  render() {
    const { children } = this.props

    return <Wrapper>{children}</Wrapper>
  }
}

export { Row }
