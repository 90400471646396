/* eslint max-lines: "off" */
import { defineMessages } from 'react-intl'

export default defineMessages({
  // NAVIGATION BAR
  navBarBookTickets: {
    id: 'nav-bar.book-tickets',
    defaultMessage: 'Book Tickets',
    description: 'Book Tickets nav bar button label',
  },
  navBarAboutCompany: {
    id: 'nav-bar.about-company',
    defaultMessage: 'About Company',
    description: 'About Company nav bar button label',
  },
  navBarOurFleet: {
    id: 'nav-bar.our-fleet',
    defaultMessage: 'Our Fleet',
    description: 'Our Fleet nav bar button label',
  },
  navBarNews: {
    id: 'nav-bar.news',
    defaultMessage: 'News',
    description: 'News nav bar button label',
  },
  navBarContacts: {
    id: 'nav-bar.contacts',
    defaultMessage: 'Contacts',
    description: 'Contacts nav bar button label',
  },
  navBarHelp: {
    id: 'nav-bar.help',
    defaultMessage: 'Help',
    description: 'Help nav bar button label',
  },
  navBarSignUp: {
    id: 'nav-bar.sign-up',
    defaultMessage: 'Sign Up',
    description: 'Sign Up nav bar button label',
  },
  navBarSignIn: {
    id: 'nav-bar.sign-in',
    defaultMessage: 'Sign In',
    description: 'Sign In nav bar button label',
  },

  // TICKET FORM
  fullFare: {
    id: 'ticket-form.full-fare-text',
    defaultMessage: 'Full-Fare ticket for a',
    description: 'Full-Fare ticket title',
  },
  quantity: {
    id: 'ticket-form.quantity-text',
    defaultMessage: 'Quantity',
    description: 'Label about quantity of tickets',
  },
  total: {
    id: 'ticket-form.total-text',
    defaultMessage: 'Total',
    description: 'Label about total cost for tickets',
  },
  paid: {
    id: 'ticket-form.paid-text',
    defaultMessage: 'Paid',
    description: 'Paid label',
  },
  unpaid: {
    id: 'ticket-form.unpaid-text',
    defaultMessage: 'Unpaid',
    description: 'Unpaid label',
  },
  auto: {
    id: 'ticket-form.auto-transport-text',
    defaultMessage: 'Auto',
    description: 'Auto label',
  },
  passenger: {
    id: 'ticket-form.passenger-text',
    defaultMessage: 'Passenger',
    description: 'Passenger label',
  },
  truck: {
    id: 'ticket-form.truck-text',
    defaultMessage: 'Truck',
    description: 'Truck label',
  },

  // CONTACT FORM FIELDS
  validateEmailError: {
    id: 'common-fields.email-correct-error',
    defaultMessage: 'Invalid email address',
    description: 'Label for invalid email address',
  },
  validatePasswordsMatch: {
    id: 'common-fields.passwords-should-match-error',
    defaultMessage: "Passwords don't match",
    description: 'Label for same old and new passwords error',
  },
  validatePasswordsDontMatch: {
    id: 'common-fields.passwords-should-not-match-error',
    defaultMessage: 'Password cannot be same as your current password',
    description: 'Label for invalid new passwords match',
  },
  validateLocalIDError: {
    id: 'common-fields.local-id-correct-error',
    defaultMessage: 'Invalid Local ID',
    description: 'Label for invalid Local ID',
  },
  validateRequiredError: {
    id: 'common-fields.required-error',
    defaultMessage: 'Required',
    description: 'Label for invalid required data',
  },
  validateNumberError: {
    id: 'common-fields.number-error',
    defaultMessage: 'Invalid number',
    description: 'Label for invalid numbers',
  },
  validateIntegerNumberError: {
    id: 'common-fields.integer-number-error',
    defaultMessage: 'Invalid integer number',
    description: 'Label for invalid integer numbers',
  },
  firstName: {
    id: 'common-fields.first-name-label',
    defaultMessage: 'First Name',
    description: 'Label for fields',
  },
  firstNameDescription: {
    id: 'common-fields.first-name-description',
    defaultMessage: 'First name',
    description: 'Description for fields',
  },
  firstNamePlaceholder: {
    id: 'common-fields.first-name-placeholder',
    defaultMessage: 'Type your first name here',
    description: 'Placeholder for fields',
  },
  lastName: {
    id: 'common-fields.last-name-label',
    defaultMessage: 'Last Name',
    description: 'Label for fields',
  },
  lastNameDescription: {
    id: 'common-fields.last-name-description',
    defaultMessage: 'Last name',
    description: 'Description for fields',
  },
  lastNamePlaceholder: {
    id: 'common-fields.last-name-placeholder',
    defaultMessage: 'Type your last name here',
    description: 'Placeholder for fields',
  },
  age: {
    id: 'common-fields.age-label',
    defaultMessage: 'Age',
    description: 'Label for fields',
  },
  ageDescription: {
    id: 'common-fields.age-description',
    defaultMessage: 'Your age',
    description: 'Description for fields',
  },
  agePlaceholder: {
    id: 'common-fields.age-placeholder',
    defaultMessage: 'Type your age here',
    description: 'Placeholder for fields',
  },
  citizenship: {
    id: 'common-fields.citizenship-label',
    defaultMessage: 'Citizenship',
    description: 'Label for fields',
  },
  loadAllCitizenships: {
    id: 'common-fields.load-more-citizenship-label',
    defaultMessage: 'Load more ...',
    description: 'Label for fields',
  },
  citizenshipDescription: {
    id: 'common-fields.citizenship-description',
    defaultMessage: 'Your citizenship',
    description: 'Description for fields',
  },
  email: {
    id: 'common-fields.email-label',
    defaultMessage: 'Email',
    description: 'Label for fields',
  },
  emailDescription: {
    id: 'common-fields.email-description',
    defaultMessage: 'Your e-mail',
    description: 'Description for fields',
  },
  emailPlaceholder: {
    id: 'common-fields.email-placeholder',
    defaultMessage: 'foo',
    description: 'Placeholder for fields',
  },
  fullName: {
    id: 'common-fields.full-name',
    defaultMessage: 'Full name',
    description: 'Label for full name',
  },
  fullNamePlaceholder: {
    id: 'common-fields.full-name-placeholder',
    defaultMessage: 'Type your Full Name here',
    description: 'Placeholder for full name',
  },
  phoneCode: {
    id: 'common-fields.phone-code',
    defaultMessage: 'Phone code',
    description: 'Label for phone code',
  },
  phoneCodePlaceholder: {
    id: 'common-fields.phone-code-placeholder',
    defaultMessage: '372',
    description: 'Placeholder for phone code',
  },
  phoneNumber: {
    id: 'common-fields.phone-number',
    defaultMessage: 'Phone number',
    description: 'Label for phone number',
  },
  phoneNumberPlaceholder: {
    id: 'common-fields.phone-number-placeholder',
    defaultMessage: '55608643',
    description: 'Placeholder for phone number',
  },
  contactPhone: {
    id: 'common-fields.contact-phone',
    defaultMessage: 'Contact Phone',
    description: 'Placeholder for contact phone',
  },
  optionalInfo: {
    id: 'common-fields.optional-info',
    defaultMessage: 'Optional info',
    description: 'Placeholder for optional info',
  },
  optionalInfoPlaceholder: {
    id: 'common-fields.optional-info-placeholder',
    defaultMessage: 'fill in if passenger has special needs',
    description: 'Placeholder for optional info',
  },
  legalNamePlaceholder: {
    id: 'common-fields.company-name-placeholder',
    defaultMessage: 'Company name',
    description: 'Placeholder for Company name',
  },
  registrationNumberPlaceholder: {
    id: 'common-fields.company-nr-placeholder',
    defaultMessage: 'Registration nr.',
    description: 'Placeholder for Registration nr.',
  },
  addressLinePlaceholder: {
    id: 'common-fields.legal-addr-placeholder',
    defaultMessage: 'Legal address',
    description: 'Placeholder for Legal address',
  },
  zipPlaceholder: {
    id: 'common-fields.zip-placeholder',
    defaultMessage: 'Postal code',
    description: 'Placeholder for Postal code',
  },
  cityPlaceholder: {
    id: 'common-fields.city-placeholder',
    defaultMessage: 'Tallinn',
    description: 'Placeholder for city',
  },
  countryPlaceholder: {
    id: 'common-fields.country-placeholder',
    defaultMessage: 'Estonia',
    description: 'Placeholder for city',
  },

  // LOCAL IDS FORM
  localIdsDescription: {
    id: 'common-fields.local-ids-description',
    defaultMessage: ' ADD LOCAL INCENTIVES TRAVELLER IDs',
    description: 'Descr for localIdsDescription',
  },
  localIdsPlaceholder: {
    id: 'common-fields.local-ids-placeholder',
    defaultMessage: 'Type your ID-code here',
    description: 'Label for smart id',
  },
  localIncentivesApplied: {
    id: 'common-fields.local-incentives-applied',
    defaultMessage: 'Local incentives applied:',
    description: 'Label for local incentives ids',
  },

  // DEPARTURE TITLE
  departureTitle: {
    id: 'departure-title.departure-title',
    defaultMessage: 'Outbound to {title}',
    description: 'Departure title on the route details',
  },

  // DEPARTURE FORM FIELDS
  sailDate: {
    id: 'departure-form-fields.sail-date',
    defaultMessage: 'Date',
    description: 'Departure date on the departure form',
  },
  sailTime: {
    id: 'departure-form-fields.sail-time',
    defaultMessage: 'Departure',
    description: 'Departure time on the departure form',
  },
  cancel: {
    id: 'departure-form-fields.sail-date-cancel',
    defaultMessage: 'Cancel',
    description: 'Cancel label for calendar',
  },

  // PAYMENT WAYS
  swedbankPaymentWay: {
    id: 'payment-ways.swedbank',
    defaultMessage: 'Pay in Swedbank',
    description: 'Way payment for custom source',
  },
  luminorPaymentWay: {
    id: 'payment-ways.luminor',
    defaultMessage: 'Pay in Luminor',
    description: 'Way payment for custom source',
  },
  lhvPaymentWay: {
    id: 'payment-ways.lhv',
    defaultMessage: 'Pay in LHV bank',
    description: 'Way payment for custom source',
  },
  visaPaymentWay: {
    id: 'payment-ways.visa',
    defaultMessage: 'Pay in Visa',
    description: 'Way payment for custom source',
  },
  maestroPaymentWay: {
    id: 'payment-ways.maestro',
    defaultMessage: 'Pay in Maestro',
    description: 'Way payment for custom source',
  },
  danskePaymentWay: {
    id: 'payment-ways.danske',
    defaultMessage: 'Pay in Danske Bank',
    description: 'Way payment for custom source',
  },
  coopPaymentWay: {
    id: 'payment-ways.coop',
    defaultMessage: 'Pay in COOP',
    description: 'Way payment for custom source',
  },
  mastercardPaymentWay: {
    id: 'payment-ways.mastercard',
    defaultMessage: 'Pay in Mastercard',
    description: 'Way payment for custom source',
  },
  sebPaymentWay: {
    id: 'payment-ways.seb',
    defaultMessage: 'Pay in S.E.B. bank',
    description: 'Way payment for custom source',
  },
  pocopayPaymentWay: {
    id: 'payment-ways.pocopay',
    defaultMessage: 'Pay in Pocopay',
    description: 'Way payment for custom source',
  },
  liisi_eePaymentWay: {
    // eslint-disable-line
    id: 'payment-ways.liisi_ee',
    defaultMessage: 'Pay with liisi_ee',
    description: 'Way payment for custom source',
  },
  paymentTerms: {
    id: 'payment-terms.terms',
    defaultMessage:
      'sinna tuleb veel hunnik selgitusi, et pilet väljastatakse peale maksmist ja pangast tuleb tagasi pöörduda jne Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex',
    description: 'Terms for payment',
  },
  addMoreTickets: {
    id: 'payment-more-tickets.label',
    defaultMessage: 'Or add more tickets to shopping cart',
    description: 'Label for adding extra tickets',
  },
  paymentFromAccount: {
    id: 'payment-ways.payment-from-account',
    defaultMessage: 'Payment from customer account',
    description: 'Label for Payment from customer account button',
  },
  creditLimitLeft: {
    id: 'payment-ways.you-have',
    defaultMessage: 'You have <b>{formattedCreditLimit}</b> left on your credit (customer) account',
    description: 'Label for you have',
  },
  notEnoughCredit: {
    id: 'payment-ways.credit-sorry',
    defaultMessage:
      'We are sorry, you have not enough funds (currently {formattedCreditLimit} left) on your customer account to cover this purchase, please, choose another payment gateway from listed below:',
    description: 'Label for you We are sorry, you have not enough funds (currently ',
  },
  withContract: {
    id: 'payment-ways.customer-with-contract',
    defaultMessage: 'Customer with contract',
    description: 'Customer with contract',
  },
  paymentFromCustomerAccount: {
    id: 'payment-ways.from-acoount',
    defaultMessage: 'Payment from customer account',
    description: 'Payment from customer account label',
  },
  boughtInCashDesk: {
    id: 'payment-ways.cash-desk',
    defaultMessage: 'Bought in cash desk',
    description: 'Bought in cash desk',
  },
  ETicket: {
    id: 'payment-ways.e-ticket',
    defaultMessage: 'E-ticket',
    description: 'E-ticket',
  },
  kiosk: {
    id: 'payment-ways.kiosk',
    defaultMessage: 'Kiosk',
    description: 'Kiosk',
  },

  // TICKETS PRINTOUT
  thanks: {
    id: 'payment-results.thanks-label',
    defaultMessage: 'Thank you!',
    description: 'Label for thanks',
  },
  paymentSucceeded: {
    id: 'payment-results.succeeded-label',
    defaultMessage: "Your payment succeeded, below you'll find your ticket(s)",
    description: 'Label for suceeded payment',
  },
  paymentSucceededLaak: {
    id: 'payment-results.succeeded-label-laak',
    defaultMessage: "Your payment succeeded, below you'll find your CONFIRMATION OF BOOKING",
    description: "Your payment succeeded, below you'll find your CONFIRMATION OF BOOKING",
  },
  bookSucceeded: {
    id: 'payment-results.book-succeeded-label',
    defaultMessage: "Your booking succeeded, below you'll find your booking receipt",
    description: 'Label for booked payment',
  },
  printout: {
    id: 'payment-results.printout-label',
    defaultMessage: 'Print out',
    description: 'Label for printout',
  },
  buyMoreTickets: {
    id: 'payment-results.buy-more-label',
    defaultMessage: 'Buy more tickets',
    description: 'Label for buy more tickets',
  },
  subTotalWithTax: {
    id: 'payment-results.sub-total-with-tax',
    defaultMessage: 'Subtotal Excl. VAT 20%',
    description: 'Label for sub total with tax',
  },
  subTotal: {
    id: 'payment-results.sub-total',
    defaultMessage: 'Subtotal',
    description: 'Label for sub total',
  },
  tax: {
    id: 'payment-results.tax',
    defaultMessage: 'VAT 20%',
    description: 'Label for tax',
  },
  paymentSupportInfo: {
    id: 'payment-results.support-info-text',
    defaultMessage:
      'Vedu teostatakse kooskõlas eeskirjaga "Sõitjate ja sõidukiteveo tüüptingimused Sõru-Triigi laevaliinil". Küsimuste korral pöörduge E-R kell 9-17 telefonile +3724431069',
    description: 'Text for support details',
  },
  additionalInfo: {
    id: 'payment-results-ticket.additional-info-text',
    defaultMessage: 'Some additional information',
    description: 'Text for additionalInfo',
  },
  adultTicket: {
    id: 'payment-results-ticket.adult-ticket-text',
    defaultMessage: 'Adult ticket',
    description: 'Text for adult ticket',
  },
  pupilTicket: {
    id: 'payment-results-ticket.pupil-ticket-text',
    defaultMessage: 'Pupil ticket',
    description: 'Text for pupil ticket',
  },
  passengerCar: {
    id: 'payment-results-ticket.passenger-car-text',
    defaultMessage: 'Passenger car',
    description: 'Text for Passenger car',
  },
  passengerBicycle: {
    id: 'payment-results-ticket.passenger-bicycle-text',
    defaultMessage: 'Passenger Bicycle',
    description: 'Text for Passenger Bicycle',
  },
  ticketNumber: {
    id: 'payment-results-ticket.ticket-number-text',
    defaultMessage: 'Ticket nr.',
    description: 'Text for Ticket nr',
  },
  bookingNumber: {
    id: 'payment-results-ticket.ticket-number-text-laak',
    defaultMessage: 'Booking nr.',
    description: 'Booking nr.',
  },
  width: {
    id: 'car-param.width',
    defaultMessage: 'width',
    description: 'Text for width label',
  },
  height: {
    id: 'car-param.height',
    defaultMessage: 'height',
    description: 'Text for height label',
  },
  weight: {
    id: 'car-param.weight',
    defaultMessage: 'Weight',
    description: 'Text for weight label',
  },
  weights: {
    id: 'car-param.weights',
    defaultMessage: 'Weights',
    description: 'Text for weights label',
  },
  length: {
    id: 'car-param.length',
    defaultMessage: 'Length',
    description: 'Text for length label',
  },
  totalLength: {
    id: 'car-param.total-length',
    defaultMessage: 'Total length',
    description: 'Text for total length label',
  },
  vehicleType: {
    id: 'car-param.type',
    defaultMessage: 'Vehicle type',
    description: 'Text for vehicle type',
  },

  // TICKETS FILTERS

  payer: {
    id: 'tickets.filter.payer',
    defaultMessage: 'Payer',
    description: 'Text for payer',
  },
  paymentMethod: {
    id: 'tickets.filter.payment-method',
    defaultMessage: 'Payment method',
    description: 'Text for paymentMethod',
  },
  more: {
    id: 'tickets.filter.more',
    defaultMessage: 'More',
    description: 'Text for more',
  },
  reset: {
    id: 'tickets.filter.reset',
    defaultMessage: 'Reset',
    description: 'Text for reset',
  },
  ticketsNotFound: {
    id: 'tickets.filter.tickets.not.found',
    defaultMessage: 'Tickets not found',
    description: 'Text for ticketsNotFound',
  },

  // TIME TRIP DETAILS
  departureDate: {
    id: 'time-trip-details.departure-date',
    defaultMessage: 'Departure date',
    description: 'Departure date label in Time Trip Details',
  },

  // INTERNAL AUTOCOMPLETE
  hintText: {
    id: 'city-search-bar.hint-text',
    defaultMessage: 'Type or select port',
    description: 'Hint for city search bar',
  },
  primaryText: {
    id: 'city-search-bar.primary-text',
    defaultMessage: 'Cities',
    description: 'Label about content in drop down list in city search bar',
  },
  search: {
    id: 'city-search-bar.search',
    defaultMessage: 'Search',
    description: 'Search label right of city search bar',
  },
  go: {
    id: 'city-search-bar.go',
    defaultMessage: 'Go',
    description: 'Go label right of city search bar',
  },

  // TicketInfoError
  ticketInfoErrorText: {
    id: 'ticket-info-form.error-text',
    defaultMessage: 'Expand to add information',
    description: 'Error message, that appeares when not all fields are filled',
  },

  // 404 NOT FOUND PAGE
  pageNotFoundSubtitle: {
    id: 'page-not-found.subtitle',
    defaultMessage: 'Go to main page and try again',
    description: 'Subtitle for Not Found page',
  },
  pageNotFoundTitle: {
    id: 'page-not-found.title',
    defaultMessage: 'This page does not exist',
    description: 'Title for Not Found page',
  },
  pageNotFoundButton: {
    id: 'page-not-found.button',
    defaultMessage: 'Back to main page',
    description: 'Label for button on Not Found page',
  },

  // CONTACT FORM
  customerInformation: {
    id: 'contact-form.customer-information',
    defaultMessage: 'Customer Information',
    description: 'Customer Information on contact form',
  },
  passengerInformation: {
    id: 'contact-form.passenger-information',
    defaultMessage: 'Passengers information',
    description: 'Passengers Information on contact form',
  },
  male: {
    id: 'contact-form.passenger-information-male',
    defaultMessage: 'Male',
    description: 'Passengers sex Information on contact form',
  },
  female: {
    id: 'contact-form.passenger-information-female',
    defaultMessage: 'Female',
    description: 'Passengers sex Information on contact form',
  },
  contactFormErrorText: {
    id: 'contact-form.error-text',
    defaultMessage: 'Please, fill in all form fields',
    description: 'Error message, that appeares when not all fields are filled',
  },
  contactFormSpecialNeed: {
    id: 'contact-form.special-needs',
    defaultMessage: 'fill in if passenger has special needs',
    description: 'Text for optional info field',
  },
  customerInformationReq: {
    id: 'contact-form.customer-information-req',
    defaultMessage: 'Customer Information. All fields are required',
    description: 'Customer Information with all requered fields',
  },
  customerSignIn: {
    id: 'contact-form.customer-sign-in',
    defaultMessage: 'Sign in for more options',
    description: 'Label for Customer sign in button',
  },
  customerRoleUsingAs: {
    id: 'contact-form.customer-role-using-as',
    defaultMessage: 'Using as:',
    description: 'Label for customer role using prefix',
  },
  contactFormPrivate: {
    id: 'contact-form.tab-title.private',
    description: 'Title for private person tab',
    defaultMessage: 'Private person',
  },
  contactRepresenter: {
    id: 'contact-form.tab-title.company.representer',
    description: 'Title for company tab',
    defaultMessage: 'Company Representer',
  },
  contactFormCompany: {
    id: 'contact-form.tab-title.company',
    description: 'Title for company tab',
    defaultMessage: 'Company',
  },
  passengersInfoTip: {
    id: 'booking-form.tip',
    description: 'user tip for add/edit passenger information',
    defaultMessage: 'Expand each of selectors to add / edit information',
  },
  passengersAgeGroupAdult: {
    id: 'booking-form.age-group.adult',
    description: 'Age group select option for adults',
    defaultMessage: 'Adult',
  },
  passengersAgeGroupChild: {
    id: 'booking-form.age-group.child',
    description: 'Age group select option for children',
    defaultMessage: 'Child (1-18 years)',
  },
  passengersAgeGroupInfant: {
    id: 'booking-form.age-group.infant',
    description: 'Age group select option for infants',
    defaultMessage: 'Infant (<1 year)',
  },
  passengerEmptyDataNotification: {
    id: 'edit-passenger-form.empty-data',
    description: 'Notification for empty fields in edit passenger form',
    defaultMessage: "Please add passenger's data",
  },

  // MAIN INSIDE COMPLETE
  finish: {
    id: 'main-inside-complete.finish-label',
    defaultMessage: 'Finish',
    description: 'Label for finish step',
  },
  sayName: {
    id: 'main-inside-complete.say-name-hint',
    defaultMessage: 'Just say your name to the ferry inspector!',
    description: 'Hint say name for main inside',
  },

  // MAIN INSIDE DESTINATION
  selectTickets: {
    id: 'main-inside-destination.select-tickets-label',
    defaultMessage: 'Select Tickets',
    description: 'Select Tickets label in destination inside info',
  },
  passengerTicket: {
    id: 'main-inside-destination.passenger-ticket-title',
    defaultMessage: 'Passenger Ticket',
    description: 'Passenger Ticket title in destination inside info',
  },
  autoTicket: {
    id: 'main-inside-destination.auto-ticket-title',
    defaultMessage: 'Auto Ticket',
    description: 'Auto Ticket title in destination inside info',
  },
  truckTicket: {
    id: 'main-inside-destination.truck-ticket-title',
    defaultMessage: 'Truck Ticket',
    description: 'Truck Ticket title in destination inside info',
  },
  moreThan: {
    id: 'main-inside-destination.more-than',
    defaultMessage: 'more than',
    description: 'label for more switcher',
  },
  lessThan: {
    id: 'main-inside-destination.less-than',
    defaultMessage: 'less than',
    description: 'label for less switcher',
  },

  // MAIN INSIDE SELECT TICKET
  checkout: {
    id: 'main-inside-destination.checkout',
    defaultMessage: 'Checkout',
    description: 'Checkout label in destination inside info',
  },
  maxAvailable: {
    id: 'main-inside-destination.max-available',
    defaultMessage: 'max available',
    description: 'Warning text in case if we choose all available tickets',
  },
  shoppingCart: {
    id: 'main-inside-select-ticket.shopping-cart-label',
    defaultMessage: 'Shopping Cart',
    description: 'Label for shoping cart info',
  },
  addVehicleTip: {
    id: 'main-inside.select-ticket-form.tips-vehicle-label',
    defaultMessage: "It's possible to add only one vehicle and/or one trailed per ticket.",
    description: 'Label for vehicle tips in select step',
  },
  chooseTicketType: {
    id: 'main-inside.select-ticket-form.choose-ticket-type-label',
    defaultMessage: 'Choose ticket type',
    description: 'Label for choose ticket type in select step',
  },
  localTravellerIDPlaceholder: {
    id: 'main-inside.local-traveller-id-placeholder',
    defaultMessage: 'Аdd local resident ID',
    description: 'Placeholder for Local traveller ID field',
  },
  localTravellerCategory: {
    id: 'main-inside.local-traveller-category',
    defaultMessage: 'Local incentives traveller',
    description: 'Placeholder for Local traveller',
  },
  checkLocalID: {
    id: 'main-inside.check-local-id-button',
    defaultMessage: 'CHECK',
    description: 'Label for Local traveller ID check button',
  },
  localIDNotFound: {
    id: 'main-inside.local-id-not-found',
    defaultMessage: 'NO incentives',
    description: 'Label for Local traveller ID field on not found',
  },
  localIDExpired: {
    id: 'main-inside.local-id-expired',
    defaultMessage: "The local traveler's discount is no valid",
    description: 'DISCOUNT_IS_EXPIRED',
  },
  localIDAlreadyApplied: {
    id: 'main-inside.local-id-already-applied',
    defaultMessage: 'Your ticket already registered on the ship',
    description: 'The discount is already used for the sail',
  },
  discountUsedForReservation: {
    id: 'main-inside.discount-used-for-reservation',
    defaultMessage: 'Same loyalty card is applied twice for the reservation',
    description: 'The discount is already used for the reservation',
  },
  failToAllocate: {
    id: 'main-inside.fail-to-allocatet',
    defaultMessage: 'No passenger tickets available',
    description: 'No passenger tickets available',
  },
  failToAllocateResident: {
    id: 'main-inside.fail-to-allocate-resident',
    defaultMessage: 'No passenger tickets available for local residents',
    description: 'No passenger tickets available for local residents',
  },
  localIDFound: {
    id: 'main-inside.local-id-found',
    defaultMessage: 'Adult, Student, Senior',
    description: 'Label for Local traveller ID field on found',
  },
  failToAllocateBicycle: {
    id: 'main-inside.fail-to-allocate-bicycle',
    defaultMessage: 'Bicycle tickets are not available',
    description: 'Bicycle tickets are not available',
  },
  addBicycleTicket: {
    id: 'main-inside.select-ticket-form.add-bicycle-ticket-label',
    defaultMessage: 'Add bicycle ticket(s)',
    description: 'Label for add bicycle ticket(s) in select step',
  },
  bookBicycleTicket: {
    id: 'main-inside.select-ticket-form.book-bicycle-ticket-label',
    defaultMessage: 'Book a bicycle',
    description: 'Label for book bicycle ticket(s) in select step',
  },
  addVehicle: {
    id: 'main-inside.select-ticket-form.add-vehicle-label',
    defaultMessage: 'Add 1 vehicle',
    description: 'Label for add vehicle in select step',
  },
  addTrailer: {
    id: 'main-inside.select-ticket-form.add-trailer-label',
    defaultMessage: 'Add trailer',
    description: 'Label for add trailer in select step',
  },

  // VEHICLE FORM
  siplifiedForm: {
    id: 'vehicle-form.simplified-form-label',
    defaultMessage: 'Simplified form',
    description: 'Label for simplified form',
  },
  inputManually: {
    id: 'vehicle-form.input-manually-label',
    defaultMessage: 'Input manually',
    description: 'Label for input manually form',
  },
  estonianPlateNumberDescr: {
    id: 'vehicle-form.plate-number-descr',
    defaultMessage: 'Input ESTONIAN car registration number and press "?" button:',
    description: 'Description for input plate number',
  },
  dataFromTDDescr: {
    id: 'vehicle-form.data-from-transportation-department-descr',
    defaultMessage: 'Here is data, provided by Transportation Department:',
    description: 'Description for data from Transportation Department',
  },
  dataFromTheClientDescr: {
    id: 'vehicle-form.data-from-the-client-descr',
    defaultMessage: 'Data shown here is provided by Reservation Owner:',
    description: 'Data shown here is provided by Reservation Owner:',
  },
  descrOfTD: {
    id: 'vehicle-form.transportation-department-descr',
    defaultMessage:
      'Estonian Transportation Dapartment is providing actual data about your vehicle based on car registration number. If no data is available, pick manual input from selector above.',
    description: 'Description for  Transportation Department',
  },
  vehiclesDefaultError: {
    id: 'vehicle-form.transportation-department-descr-failed',
    defaultMessage:
      'Sorry! Ticket type for this vehicle was not found for some reason. Please try to Complete Your Online Purchase switching to a <action>manual vehicle input</action>.',
    description: 'Description for  Transportation Department failed',
  },
  vehicleWrongFormError: {
    id: 'vehicle-form.vehicle-wrong-form',
    defaultMessage: 'NB! Please use "Add Trailer" form to add your trailer\'s Licence Plate Number into reservation.',
    description: 'error, if trailer number entered in vehicles form',
  },
  vehicleIsNotConfirmed: {
    id: 'vehicle-form.vehicle-is-not-confirmed',
    defaultMessage: 'Vehicle ticket was not added. Please press the confirm button to add ticket.',
    description: 'Notification text if vehicle/trailer form was started, but was not confirmed.',
  },
  trailerWrongFormError: {
    id: 'vehicle-form.trailer-wrong-form',
    defaultMessage: 'NB! Please use "Add Vehicle" form to add your vehicle\'s Licence Plate Number into reservation.',
    description: 'error, if vehicles number entered in trailer form',
  },
  customWeightFormError: {
    id: 'custom-weight-form.weight-wrong-form',
    defaultMessage: "Sorry! There is not enough space on the vessel. Currently we can't ship your vehicle.",
    description: 'error, if vehicles weight is too big',
  },
  chooseFromSaved: {
    id: 'vehicle-form.choose-saved-input',
    defaultMessage: 'Choose from saved',
    description: 'Label for saved vehicles choosen',
  },
  chooseVehicleType: {
    id: 'vehicle-form.choose-vehicle-type-input',
    defaultMessage: 'Choose vehicle type',
    description: 'Label for vehicles types choosen',
  },
  licensePlateNumber: {
    id: 'vehicle-form.license-plate-number-input',
    defaultMessage: 'Licence Plate number',
    description: 'Label for plate number input',
  },
  confirmDataForVehicle: {
    id: 'vehicle-form.data-vehicle-confirm-button',
    defaultMessage: 'click to confirm that all data is correct and to add this ticket to your purchase',
    description: 'Label for confirm vehicle data button',
  },
  changeWeightManually: {
    id: 'vehicle-form.data-vehicle-edit-manually-button',
    defaultMessage: 'Change manually',
    description: 'Label for editing vehicle weight',
  },
  discardEdit: {
    id: 'vehicle-form.discard-edit',
    defaultMessage: 'Discard',
    description: 'Label for discard changes',
  },
  saveEdit: {
    id: 'vehicle-form.save-edit',
    defaultMessage: 'Save',
    description: 'Label for save changes',
  },
  invalidWeight: {
    id: 'vehicle-form.invalid-weight',
    defaultMessage: 'Added weight can´t be lower than kerb mass',
    description: 'Error message for invalid manually entered weight',
  },
  invalidWeightModal: {
    id: 'vehicle-form.invalid-weight-modal',
    defaultMessage: 'Added weight can´t be lower than kerb mass',
    description: 'Error message for invalid entered weight on modal',
  },
  dataForVehicleWasAdded: {
    id: 'vehicle-form.label-for-added-vehicle-button',
    defaultMessage: 'vehicle ticket successfully added',
    description: 'Label for added vehicle data button',
  },
  inputManuallyDescr: {
    id: 'vehicle-form.input-manually-descr',
    defaultMessage: 'If you need to edit some information, use manual input from selector above.',
    description: 'Descr for manually input',
  },
  trailerTitle: {
    id: 'vehicle-form.trailer-title',
    defaultMessage: 'Please, choose correct trailer type',
    description: 'Trailer title',
  },
  trailerSmall: {
    id: 'vehicle-form.trailer-small',
    defaultMessage: 'Trailer with fully loaded weight less than 3.5 t',
    description: 'label for small trailer',
  },
  trailerBig: {
    id: 'vehicle-form.trailer-big',
    defaultMessage: 'Trailer with fully loaded weight more than 3.5 t',
    description: 'label for big trailer',
  },
  trailerCamper: {
    id: 'vehicle-form.trailer-camper',
    defaultMessage: 'Camper trailer',
    description: 'label for Camper trailer',
  },
  trailer: {
    id: 'vehicle-form.trailer',
    defaultMessage: 'Trailer',
    description: 'label for trailer',
  },
  handicappedMoreOptions: {
    id: 'vehicle-form.handicapped-more-options',
    defaultMessage: 'More options',
    description: 'label for More options',
  },
  handicappedLessOptions: {
    id: 'vehicle-form.handicapped-less-options',
    defaultMessage: 'Less options',
    description: 'label for Less options',
  },
  allOption: {
    id: 'user-tickets-view.all-option',
    defaultMessage: 'ALL',
    description: 'label for allOption',
  },
  handicappedDescription: {
    id: 'vehicle-form.handicapped-descr',
    defaultMessage: 'Car transporting person with profound disability,with profound visual impairment or disable child',
    description: 'label for Less Description',
  },
  companyRegistrationNumberDescr: {
    id: 'vehicle-form.company-reg-number-descr',
    defaultMessage: 'If vehicle is owned by company, fill in company registration number below:',
    description: 'Company registration button description',
  },
  companyRegistrationNumberHint: {
    id: 'vehicle-form.company-reg-number-hint',
    defaultMessage: 'Registration number',
    description: 'Company registration button hint',
  },
  companyRegistrationNumberBtn: {
    id: 'vehicle-form.company-reg-number-btn',
    defaultMessage: 'CHECK / SAVE',
    description: 'Company registration button text',
  },
  companyRegistrationNumberErrorNotFound: {
    id: 'vehicle-form.company-reg-number-error-not-found',
    defaultMessage: 'Company registration number is not found, please check the number.',
    description: 'Company not found error',
  },
  // MAIN INSIDE VIEW
  destination: {
    id: 'main-inside-view-progress.destination-step',
    defaultMessage: 'Select date and time',
    description: 'Destination step in order',
  },
  selectTicketStep: {
    id: 'main-inside-view-progress.select-ticket-step',
    defaultMessage: 'Select Ticket(s)',
    description: 'Select Ticket step in order',
  },
  confirmAndPay: {
    id: 'main-inside-view-progress.select-confirm-step',
    defaultMessage: 'Confirm & Pay',
    description: 'Confirm & Pay step in order',
  },
  complete: {
    id: 'main-inside-view-progress.select-complete-step',
    defaultMessage: 'Complete',
    description: 'Complete step in order',
  },
  help: {
    id: 'main-inside-view-progress.help',
    defaultMessage: 'Help',
    description: 'Help in main inside view top bar',
  },
  pricelist: {
    id: 'main-inside-view-progress.pricelist',
    defaultMessage: 'Pricelist and schedule',
    description: 'Pricelist in main inside view top bar',
  },

  // MAIN VIEW
  goBack: {
    id: 'main-view.go-back-question',
    defaultMessage: 'Need a ticket to go back?',
    description: 'Question for back trip',
  },
  goBackToChangeRoute: {
    id: 'main-view.go-back-change-route',
    defaultMessage: 'Go back to change route',
    description: 'label for go back for first step',
  },
  date: {
    id: 'main-view.date-label',
    defaultMessage: 'Date',
    description: 'Date label for main view',
  },
  departure: {
    id: 'main-view.departure-label',
    defaultMessage: 'Departure',
    description: 'Departure label for main view',
  },
  direction: {
    id: 'main-view.direction-label',
    defaultMessage: 'Direction',
    description: 'Direction label for main view',
  },
  continue: {
    id: 'main-view.continue-label',
    defaultMessage: 'continue to',
    description: 'Continue label for main view',
  },
  selectTicket: {
    id: 'main-view.select-ticket-label',
    defaultMessage: 'Select Ticket',
    description: 'Select ticket label for main view',
  },
  arrivalTime: {
    id: 'main-view.arrival-label',
    defaultMessage: 'Arrival',
    description: 'Arrival label for main view',
  },
  select: {
    id: 'main-view.select-label',
    defaultMessage: 'Select',
    description: 'Select label for main view',
  },
  selected: {
    id: 'main-view.selected-label',
    defaultMessage: 'Selected',
    description: 'Selected label for main view',
  },
  ticketsAvailability: {
    id: 'main-view.availability-label',
    defaultMessage: 'Tickets Availability',
    description: 'Tickets Availability label for main view',
  },
  soldOut: {
    id: 'main-view.soldOut-label',
    defaultMessage: 'Sold Out',
    description: 'Sold Out label for main view',
  },
  noteAboutTicket: {
    id: 'main-view.note-about-ticket',
    defaultMessage: '* no more pre-sales available, but up to 20% of tickets are obligatory for sale in harbour.',
    description: 'Note about ticket',
  },
  noteAboutDangerous: {
    id: 'main-view.note-about-dangerous-goods',
    defaultMessage: 'This sail is shipping Dangerous Goods',
    description: 'Note about dangerous goods',
  },
  noteNoHeavyTrucks: {
    id: 'main-view.note-about-no-heavy-tracks',
    defaultMessage: 'Heavy trucks are restricted on this sail',
    description: 'Heavy tracks are restricted on this sail',
  },
  noteAboutCancelled: {
    id: 'main-view.note-about-cancelled',
    defaultMessage: 'This Sail is cancelled',
    description: 'Note about cancelled',
  },
  noteAboutHovercraft: {
    id: 'main-view.note-about-hovercraft',
    defaultMessage: 'This Sail is hovercraft',
    description: 'Note about hovercraft',
  },
  pickTime: {
    id: 'main-view.pickTime-label',
    defaultMessage: 'Pick Time',
    description: 'Pick Time label for main view',
  },
  pickDate: {
    id: 'main-view.pickDate-label',
    defaultMessage: 'Pick Date',
    description: 'Pick Date label for main view',
  },
  pickDeparture: {
    id: 'main-view.pickDeparture-label',
    defaultMessage: 'Pick Departure',
    description: 'Pick Departure label for main view',
  },
  addReturnRoute: {
    id: 'main-view.add-returnRoute-label',
    defaultMessage: 'Add return route',
    description: 'Add return route label for main view',
  },
  changeDirection: {
    id: 'main-view.changeDirection-label',
    defaultMessage: 'Сhange direction',
    description: 'Change direction label for main view',
  },
  approximateTime: {
    id: 'main-view.approximate-time',
    defaultMessage: 'Travel time approximately',
    description: 'Approximate time label for main view',
  },
  goBackToChangeDateTime: {
    id: 'main-view.go-back-change-date-time',
    defaultMessage: 'Go back to change date and time',
    description: 'label for go back for second step',
  },
  chooseReturnRoute: {
    id: 'main-view.choose-return-route',
    defaultMessage: 'choose return route',
    description: 'label for choose route tooltip',
  },

  // MY TICKETS VIEW
  history: {
    id: 'my-tickets-view.history-label',
    defaultMessage: 'History of',
    description: 'History of user tickets',
  },
  boughtTickets: {
    id: 'my-tickets-view.bought-tickets-label',
    defaultMessage: 'Bought Tickets',
    description: 'Purchased tickets of user',
  },

  // PROMOTIONS VIEW
  promoText: {
    id: 'promotions-view.promo-text',
    defaultMessage: 'From',
    description: 'Promotions promo text label',
  },
  mainlandText: {
    id: 'promotions-view.mainland-text',
    defaultMessage: 'Mainland',
    description: 'Promotions mainland text label',
  },

  // ROOT CONTAINER
  appTitle: {
    id: 'app.title',
    defaultMessage: 'BORA',
    description: 'App title',
  },
  logo: {
    id: 'app.logo',
    defaultMessage: 'LOGO',
    description: 'App logo',
  },
  logOut: {
    id: 'app.log-out',
    defaultMessage: 'Log Out',
    description: 'Text of Log Out link',
  },
  profile: {
    id: 'app.profile',
    defaultMessage: 'Profile',
    description: 'Text of Profile link',
  },
  routeSelect: {
    id: 'app.route-select',
    defaultMessage: 'Select route to buy',
    description: 'Text of route select',
  },
  orRouteSelect: {
    id: 'app.or.route-select',
    defaultMessage: 'Or select a route',
    description: '.',
  },
  searchTicket: {
    id: 'app.search.ticket',
    defaultMessage: 'Search for a ticket',
    description: '.',
  },
  cookieBannerDescription: {
    id: 'app.cookie-banner-description',
    defaultMessage: 'Website {url} uses cookies',
    description: 'Description for cookie banner',
  },
  privacyPolicy: {
    id: 'app.privacy-policy',
    defaultMessage: 'privacy policy',
    description: 'Description privacy policy link',
  },
  agreePrivacyPolicy: {
    id: 'app.agree-privacy-policy',
    defaultMessage: 'Agree privacy policy',
    description: 'Agree privacy policy',
  },

  // INDEX
  selectRoute: {
    id: 'common-hint.select-route',
    defaultMessage: 'First, select route, please',
    description: 'Hint for situations when route not found',
  },

  // NOTIFICATIONS
  errorNotificationType: {
    id: 'common-notification.error',
    defaultMessage: 'error',
    description: 'Error notification type',
  },

  errorNotificationTitle: {
    id: 'common-notification.error-desc',
    defaultMessage: 'Something went wrong!',
    description: 'Error notification title',
  },

  usualNotificationType: {
    id: 'common-notification.usual',
    defaultMessage: 'notification',
    description: 'Usual notification type',
  },

  usualNotificationTitle: {
    id: 'common-notification.usual-desc',
    defaultMessage: 'You forgot something',
    description: 'Usual notification title',
  },

  // RESERVATION
  yourReservationId: {
    id: 'reservation.your-reservation-title',
    defaultMessage: 'Your reservation ID',
    description: 'Title for your reservation',
  },

  yourTickets: {
    id: 'reservation.your-tickets-title',
    defaultMessage: 'Your tickets',
    description: 'Title for tickets',
  },

  vessle: {
    id: 'reservation.vessle-title',
    defaultMessage: 'Vessle',
    description: 'Title for vessle',
  },

  class: {
    id: 'reservation.class-title',
    defaultMessage: 'Class',
    description: 'Title for class',
  },

  boarding: {
    id: 'reservation.boarding-title',
    defaultMessage: 'Boarding',
    description: 'Title for boarding',
  },

  // CHECKIN
  checkInTitleWeNeed: {
    id: 'checkin.we-need-title',
    defaultMessage: 'We need',
    description: 'Title for checkin',
  },
  checkInTitleInformation: {
    id: 'checkin.information-title',
    defaultMessage: 'Your Information',
    description: 'Title for checkin',
  },
  checkInAlrightLabel: {
    id: 'checkin.alright-label',
    defaultMessage: 'alright',
    description: 'Label for alright',
  },
  checkInGetTicketsLabel: {
    id: 'checkin.get-tickets-label',
    defaultMessage: 'Get my Tickets',
    description: 'Label for Get tickets button',
  },
  checkinName: {
    id: 'checkin.name',
    defaultMessage: 'Your name',
    description: 'Title for name on checkin page',
  },
  checkinNamePlaceholder: {
    id: 'checkin.userName-placeholder',
    defaultMessage: 'Type your name here',
    description: 'Placeholder for checkin username',
  },
  checkinReservationId: {
    id: 'checkin.reservationId',
    defaultMessage: 'Reservation ID',
    description: 'Title for reservationId on checkin page',
  },
  checkinReservationIdPlaceholder: {
    id: 'checkin.reservationId-placeholder',
    defaultMessage: 'Type your reservation number',
    description: 'Placeholder for checkin reservationId',
  },

  // LOGIN
  welcomeBack: {
    id: 'login.welcome-back-title',
    defaultMessage: 'Welcome back!',
    description: 'Title for sign screen',
  },
  signIn: {
    id: 'login.sign-in-title',
    defaultMessage: 'Sign in',
    description: 'Title for sign in',
  },
  signUp: {
    id: 'login.sign-up-title',
    defaultMessage: 'Sign up',
    description: 'Title for sign up',
  },
  signForgotPass: {
    id: 'login.sign-forgot-pass',
    defaultMessage: 'Forgot password?',
    description: 'Title for sign in',
  },
  signFB: {
    id: 'login.sign-fb-title',
    defaultMessage: 'Sign in with facebook',
    description: 'Title for fb sign',
  },
  signGoogle: {
    id: 'login.sign-google-title',
    defaultMessage: 'Sign in with google',
    description: 'Title for google sign',
  },
  or: {
    id: 'login.or-title',
    defaultMessage: 'or',
    description: 'Title for or',
  },
  signMail: {
    id: 'sign-fields.mail-label',
    defaultMessage: 'Your Mail',
    description: 'Label for fields',
  },
  signMailDescription: {
    id: 'sign-fields.mail-description',
    defaultMessage: 'Your Mail',
    description: 'Description for fields',
  },
  signMailPlaceholder: {
    id: 'sign-fields.mail-placeholder',
    defaultMessage: 'hello@mail.com',
    description: 'Description for fields',
  },
  recoveryMailPlaceholder: {
    id: 'recovery-fields.mail-placeholder',
    defaultMessage: 'hello@mail.com',
    description: 'Description for fields',
  },
  signPassword: {
    id: 'sign-fields.password-label',
    defaultMessage: 'password',
    description: 'Label for fields',
  },
  signPasswordDescription: {
    id: 'sign-fields.password-description',
    defaultMessage: 'Your password',
    description: 'Description for fields',
  },
  signPasswordPlaceholder: {
    id: 'sign-fields.password-placeholder',
    defaultMessage: 'type your password',
    description: 'Description for fields',
  },

  nationalId: {
    id: 'sign-fields.national-label',
    defaultMessage: 'National ID number',
    description: 'Label for fields',
  },
  nationalIdDescription: {
    id: 'sign-fields.national-description',
    defaultMessage: 'National ID number',
    description: 'Description for fields',
  },
  nationalIdPlaceholder: {
    id: 'sign-fields.national-placeholder',
    defaultMessage: 'National ID number',
    description: 'Description for fields',
  },
  enter: {
    id: 'sign-in.enter',
    defaultMessage: 'Enter',
    description: 'Label for Enter button',
  },
  chooseMethod: {
    id: 'sign-in.intro',
    defaultMessage: 'Please, choose authentication method you prefer.',
    description: 'Label for inter words in sign-in',
  },

  // SIGNUP
  welcomeToFamily: {
    id: 'signup.welcome-family-title',
    defaultMessage: 'Welcome to our family, you’re goona like it.',
    description: 'Title for signup screen',
  },
  signUpHeader: {
    id: 'signup.sign-up-header',
    defaultMessage: 'Create Account / Sign up',
    description: 'Title for sign up header',
  },
  signUpInfoText: {
    id: 'signup.sign-up-info-text',
    defaultMessage:
      "Please sign up to save your data for future purchases. This will significantly speed up overall process and you'll surely like it. You're data is protected and safe, and we never send spam to our customers.",
    description: 'Info text for sign up',
  },
  signUpUserExists: {
    id: 'signup.sign-up-user-exists',
    defaultMessage: 'User exists. Please use log in.',
    description: 'Info text for already existing user',
  },
  signupFB: {
    id: 'signup.signup-fb-title',
    defaultMessage: 'facebook',
    description: 'Title for fb sign up',
  },
  signupGoogle: {
    id: 'signup.signup-google-title',
    defaultMessage: 'google',
    description: 'Title for google signup',
  },
  signLoyalty: {
    id: 'sign.sign-loyalty-title',
    defaultMessage: 'loyalty user code',
    description: 'Title for loyalty sign',
  },
  signSmartID: {
    id: 'sign.sign-smartID-title',
    defaultMessage: 'smart id',
    description: 'Title for smart ID sign',
  },
  signSmartIDLoginError: {
    id: 'sign.sign-smartID.login.error',
    defaultMessage: 'We cannot sign you in using this auth method at the moment, please try another option',
    description: 'Display this friendly message in case of an error',
  },
  mobileIdSignInError: {
    id: 'sign.sign-mobileID.login.error',
    defaultMessage: 'Authentication error. Your Mobile-ID transaction has expired. Please try again.',
    description: 'Display this friendly message in case of mobile id error',
  },
  mobileIdCancelledByUser: {
    id: 'sign.sign-mobileID.cancelled.by.user.error',
    defaultMessage: 'You cancelled operation from your phone.',
    description: 'Display this friendly message in case of mobile id error',
  },
  mobileIdNotAClient: {
    id: 'sign.sign-mobileID.not.a.client.error',
    defaultMessage:
      'You are not a Mobile-ID client or your Mobile-ID certificates are revoked. Please contact your mobile operator.',
    description: 'Display this friendly message in case of mobile id error',
  },
  mobileIdTimeOut: {
    id: 'sign.sign-mobileID.timeout.error',
    defaultMessage: "You didn't type in PIN code into your phone or there was a communication error.",
    description: 'Display this friendly message in case of mobile id error',
  },
  mobileIdPhoneNotAvailable: {
    id: 'sign.sign-mobileID.phone.not.available.error',
    defaultMessage: 'Unable to reach your phone. Please make sure your phone has mobile coverage.',
    description: 'Display this friendly message in case of mobile id error',
  },
  mobileIdCommunicationError: {
    id: 'sign.sign-mobileID.communication.error',
    defaultMessage: 'Communication error. Unable to reach your phone.',
    description: 'Display this friendly message in case of mobile id error',
  },
  mobileIdInvalidUserConfiguration: {
    id: 'sign.sign-mobileID.invalid.user.configuration.error',
    defaultMessage:
      "Mobile-ID configuration on your SIM card differs from what is configured on service provider's side. Please contact your mobile operator.",
    description: 'Display this friendly message in case of mobile id error',
  },
  mobileIdClientError: {
    id: 'sign.sign-mobileID.client.error',
    defaultMessage: 'Client side error with mobile-ID integration.',
    description: 'Display this friendly message in case of mobile id error',
  },
  mobileIdInternalError: {
    id: 'sign.sign-mobileID.internal.error',
    defaultMessage: 'Mobile ID internal error. Please try again later.',
    description: 'Display this friendly message in case of mobile id error',
  },
  mobileIdInvalidPersonalId: {
    id: 'sign.sign-mobileID.invalid.personal.id.error',
    defaultMessage: 'Invalid Personal Identification Number. Please check the ID code and try again',
    description: 'Display this friendly message in case of mobile id error',
  },
  mobileIdInvalidPhoneNumber: {
    id: 'sign.sign-mobileID.invalid.phone.number.error',
    defaultMessage: 'Invalid phone number. Please check the phone number and try again',
    description: 'Display this friendly message in case of mobile id error',
  },
  signSmartIDLoginInterrupt: {
    id: 'sign.sign-smartID.login.interrupt',
    defaultMessage: 'Login process was cancelled due to an interrupt: please try again or use another login method!',
    description: 'Display this friendly message in case of an interrupt',
  },
  cardIdSignInError: {
    id: 'sign.sign-cardID.login.error',
    defaultMessage: 'ID Card Authentication error',
    description: 'Display this friendly message in case of id card id error',
  },
  signIDKaart: {
    id: 'sign.sign-IDKaart-title',
    defaultMessage: 'id-kaart',
    description: 'Title for ID-KAART sign',
  },
  signMobiilID: {
    id: 'sign.sign-mobiilID-title',
    defaultMessage: 'mobiil-id',
    description: 'Title for Mobiil ID sign',
  },
  signUpEmailPlaceholder: {
    id: 'signup.sign-up-email-paceholder',
    defaultMessage: 'E-mail',
    description: 'Title for sign up email paceholder',
  },
  signUpPasswordPlaceholder: {
    id: 'signup.sign-up-password-paceholder',
    defaultMessage: 'Password',
    description: 'Title for sign up password paceholder',
  },
  signUpButton: {
    id: 'signup.sign-up-button',
    defaultMessage: 'Sign up',
    description: 'Title for sign up button',
  },

  // LANGUAGE
  languageWelcome: {
    id: 'language.welcome-title',
    defaultMessage: 'Hello! Welcome to the best ticket finder for your flawless marine journey.',
    description: 'Title for welcome language',
  },
  goToApp: {
    id: 'language.to-app-button',
    defaultMessage: 'Switch to english version',
    description: 'Title for go to app language',
  },
  chooseAnother: {
    id: 'language.choose-another-button',
    defaultMessage: 'I want to choose another language',
    description: 'Title for switch language',
  },
  rightsReserved: {
    id: 'language.rights-reserved-title',
    defaultMessage: 'All rights reserved.',
    description: 'Title for rights language',
  },

  // RECOVERY
  recoveryLinkSent: {
    id: 'recovery.link-sent-title',
    defaultMessage: "We'll email you a link to reset it.",
    description: 'Title for recovery link sent title',
  },
  recoveryLinkSentThirdParty: {
    id: 'recovery.link-sent-third-party-descr',
    defaultMessage: 'If you signed up using a third party service such as Facebook, please login with that instead.',
    description: 'Title for descr third party',
  },
  send: {
    id: 'recovery.send-button',
    defaultMessage: 'Send',
    description: 'Title for send button',
  },
  recoveryTitle: {
    id: 'recovery.title',
    defaultMessage: 'Password Recovery',
    description: 'Title for recovery',
  },
  recoveryIntro: {
    id: 'recovery.intro',
    defaultMessage:
      'Please, input email address you have used to register, we will send you instructions how to reset and recover your password',
    description: 'Intro for recovery',
  },
  emailShort: {
    id: 'common-fields.email-short-label',
    defaultMessage: 'Email',
    description: 'Label for fields',
  },
  emailShortDescription: {
    id: 'common-fields.email-short-description',
    defaultMessage: 'Your e-mail',
    description: 'Description for fields',
  },
  emailShortPlaceholder: {
    id: 'common-fields.email-short-placeholder',
    defaultMessage: 'E-mail',
    description: 'Placeholder for fields',
  },

  // PROMOCODE
  promocode: {
    id: 'promocode.promocode-label',
    defaultMessage: 'Promocode',
    description: 'Title for promocode field',
  },

  'promo-NAR-NAJ-NAR_FAMILY_DISCOUNT': {
    id: 'promo.nar-naj-nar-family',
    defaultMessage: 'Family ticket discount applied',
    description: 'Family ticket discount applied',
  },
  'promo-acc-NAR-NAJ-NAR_FAMILY_DISCOUNT': {
    id: 'promo.acc.nar-naj-nar-family',
    defaultMessage: 'Total family discount applied',
    description: 'Total family discount applied',
  },
  'promo-TAL-NAI-TAL_FAMILY_DISCOUNT': {
    id: 'promo.tal-nai-tal-family',
    defaultMessage: 'Family ticket discount applied',
    description: 'Family ticket discount applied',
  },
  'promo-acc-TAL-NAI-TAL_FAMILY_DISCOUNT': {
    id: 'promo.acc.tal-nai-tal-family',
    defaultMessage: 'Total family discount applied',
    description: 'Total family discount applied',
  },
  promocodeDescr: {
    id: 'promocode.promocode-descr',
    defaultMessage: 'Promocode',
    description: 'Descr for promocode field',
  },
  failedToApplyPromotion: {
    id: 'promocode.failed',
    defaultMessage: 'Promotion not found',
    description: 'Promotion not found',
  },

  //HOTEL
  textHotelTitle: {
    id: 'textHotel.textHotel-label',
    defaultMessage: 'Hotel pickup area',
    description: 'Label for hotel',
  },

  // SETTINGS
  settings: {
    id: 'settings.settings-title',
    defaultMessage: 'Settings',
    description: 'Title for settings',
  },
  settingsDescr: {
    id: 'settings.settings-descr',
    defaultMessage: 'Change your credentials',
    description: 'Descr for settings',
  },
  settingsPassword: {
    id: 'settings.password-title',
    defaultMessage: 'Password',
    description: 'Title for settings password',
  },
  settingsPasswordUpdate: {
    id: 'settings.password-update-button',
    defaultMessage: 'Update password',
    description: 'Title for update password button',
  },
  settingsName: {
    id: 'settings-fields.name-label',
    defaultMessage: 'Change name',
    description: 'Label for fields',
  },
  settingsNameDescription: {
    id: 'settings-fields.name-description',
    defaultMessage: 'Change name',
    description: 'Description for fields',
  },
  settingsNamePlaceholder: {
    id: 'settings-fields.name-placeholder',
    defaultMessage: 'Type your name',
    description: 'Description for fields',
  },
  settingsEmail: {
    id: 'settings-fields.email-label',
    defaultMessage: 'Change email',
    description: 'Label for fields',
  },
  settingsEmailDescription: {
    id: 'settings-fields.email-description',
    defaultMessage: 'Change mail',
    description: 'Description for fields',
  },
  settingsEmailPlaceholder: {
    id: 'settings-fields.email-placeholder',
    defaultMessage: 'Change email',
    description: 'Description for fields',
  },
  oldPassword: {
    id: 'settings-fields.old-password-label',
    defaultMessage: 'Old password',
    description: 'Label for fields',
  },
  oldPasswordDescription: {
    id: 'settings-fields.old-password-description',
    defaultMessage: 'Old password',
    description: 'Description for fields',
  },
  oldPasswordPlaceholder: {
    id: 'settings-fields.old-password-placeholder',
    defaultMessage: 'Do you remember?',
    description: 'Description for fields',
  },
  newPassword: {
    id: 'settings-fields.new-password-label',
    defaultMessage: 'New password',
    description: 'Label for fields',
  },
  newPasswordDescription: {
    id: 'settings-fields.new-password-description',
    defaultMessage: 'New password',
    description: 'Description for fields',
  },
  newPasswordPlaceholder: {
    id: 'settings-fields.new-password-placeholder',
    defaultMessage: 'This one will be better',
    description: 'Description for fields',
  },

  // TERMS & CONDITIONS
  termsAndConditions: {
    id: 'terms-and-conditions.title',
    defaultMessage: 'Terms & conditions',
    description: 'Label for Yes',
  },
  yes: {
    id: 'terms-and-conditions.yes-label',
    defaultMessage: 'Yes',
    description: 'Label for Yes',
  },
  no: {
    id: 'terms-and-conditions.no-label',
    defaultMessage: 'No',
    description: 'Label for No',
  },
  termsQuestion: {
    id: 'terms-and-conditions.question-label',
    defaultMessage: 'You agree to our',
    description: 'Label for terms question',
  },
  termsLabel: {
    id: 'terms-and-conditions.terms-label',
    defaultMessage: 'Terms',
    description: 'Label for Terms',
  },
  termsQue: {
    id: 'terms-and-conditions.terms-question',
    defaultMessage: 'I AM AGREE WITH CONDITIONS OF SERVICE:',
    description: 'Label for Terms title',
  },

  // MY RESERVATIONS
  listOfAllMy: {
    id: 'my-reservations.list-of-all-my-title',
    defaultMessage: 'List of all my',
    description: 'Title for listOfAllMy Reservations',
  },
  reservations: {
    id: 'my-reservations.reservations-title',
    defaultMessage: 'Reservations',
    description: 'Title for Reservations',
  },
  searchReservations: {
    id: 'my-reservations.search-reservations-placeholder',
    defaultMessage: 'Search my reservations',
    description: 'Placeholder for search reservations',
  },
  futureVoyages: {
    id: 'my-reservations.future-voyages-label',
    defaultMessage: 'Future voyages',
    description: 'Label for future voyages',
  },
  finishedVoyages: {
    id: 'my-reservations.finished-voyages-label',
    defaultMessage: 'Finished',
    description: 'Label for finished voyages',
  },
  tickets: {
    id: 'my-reservations.tickets-label',
    defaultMessage: 'Tickets',
    description: 'Label for Tickets',
  },
  editTickets: {
    id: 'my-reservations.edit-tickets',
    defaultMessage: 'Edit Tickets',
    description: 'Label for edit tickets',
  },
  editTicketTypes: {
    id: 'my-reservations.edit-ticket-types',
    defaultMessage: 'Edit Ticket Types',
    description: 'Label for edit ticket types',
  },
  noCarsAndBicyclesOnSail: {
    id: 'ticket-error.no-cars-and-bicycles',
    defaultMessage: 'NB! Change of date is not possible. Selected sail does not serve bicycle and vehicle tickets.',
    description: 'Error for unsupported inventory after sail changed',
  },
  removeTickets: {
    id: 'my-reservations.remove-tickets',
    defaultMessage: 'Remove Tickets',
    description: 'Label for edit remove tickets',
  },
  removedAllItemsError: {
    id: 'my-reservations.removed-all-items-error',
    defaultMessage: 'Please add at least 1 ticket or use the "Remove" button to cancel all tickets in the reservation.',
    description: 'Label for remove all tickets error',
  },
  excludedPriceCategory: {
    id: 'price.category.excluded.message',
    defaultMessage: 'You cannot buy a ticket for a vehicle of this Category for this Sail',
    description: 'You cannot buy a ticket for a vehicle of this Category for this Sail',
  },
  excludedPriceCategoryEditSail: {
    id: 'price.category.excluded.message-edit-sail',
    defaultMessage: 'Date change is not allowed. You cannot buy a ticket for a vehicle of this Category for this Sail',
    description: 'Date change is not allowed. You cannot buy a ticket for a vehicle of this Category for this Sail',
  },

  // ONE OF MY RESERVATIONS
  ticketPaid: {
    id: 'my-reservation.ticket-paid-label',
    defaultMessage: 'Ticket paid',
    description: 'Label for ticket-paid',
  },
  ticketUnpaid: {
    id: 'my-reservation.ticket-unpaid-label',
    defaultMessage: 'Ticket unpaid',
    description: 'Label for ticket-unpaid',
  },
  payAdditional: {
    id: 'my-reservation.pay-additional-button',
    defaultMessage: 'Pay additional',
    description: 'Label for payAdditional button',
  },
  initialRoute: {
    id: 'my-reservation.initial-route-button',
    defaultMessage: 'Initial route',
    description: 'Label for initial route button',
  },
  returnRoute: {
    id: 'my-reservation.return-route-button',
    defaultMessage: 'Return route',
    description: 'Label for return route button',
  },
  departureArrivalDates: {
    id: 'my-reservation.departure-arrival-dates',
    defaultMessage: 'Departure & Arrival dates',
    description: 'Label for departure arrival dates',
  },
  vessleName: {
    id: 'my-reservation.vessle-name',
    defaultMessage: 'Vessle name',
    description: 'Label for vessle name',
  },
  type: {
    id: 'my-reservation.type-label',
    defaultMessage: 'Type',
    description: 'Label for type',
  },
  price: {
    id: 'my-reservation.price-label',
    defaultMessage: 'Price',
    description: 'Label for price',
  },
  options: {
    id: 'my-reservation.options-label',
    defaultMessage: 'Options',
    description: 'Label for options',
  },
  payment: {
    id: 'my-reservation.payment-label',
    defaultMessage: 'Payment',
    description: 'Label for payment',
  },
  printButton: {
    id: 'my-reservation.print-button',
    defaultMessage: 'Print',
    description: 'Label for print button',
  },
  requestRefundButton: {
    id: 'my-reservation.request-refund-button',
    defaultMessage: 'Request a refund',
    description: 'Label for request a refund button',
  },
  refundTitle: {
    id: 'my-reservation.refund-title',
    defaultMessage: 'Refund',
    description: 'Title for Refund',
  },
  refundDescr: {
    id: 'my-reservation.refund-descr',
    defaultMessage: 'Refund will be only 25%, because your sail is scheduled tommorow.',
    description: 'Descr for Refund',
  },
  refundConfirmQuestion: {
    id: 'my-reservation.refund-confirm-question',
    defaultMessage: 'Do you relly want to refund?',
    description: 'Question for Refund',
  },
  paymentWarningTitle: {
    id: 'my-reservation.payment-warning-title',
    defaultMessage: 'WARNING! PAYMENT WAS NOT COMPLETED!',
    description: 'title for error payment',
  },
  paymentWarningInfoText: {
    id: 'my-reservation.payment-warning-info-text',
    defaultMessage: 'There has been an error during payment process. Here is transcript from payment gateway:',
    description: 'info text for error payment',
  },
  paymentWarningFinalText: {
    id: 'my-reservation.payment-warning-final-text',
    defaultMessage: 'But you still may try again or pay for your order using other payment gateways.',
    description: 'ingo text for error payment',
  },
  warningTitle: {
    id: 'my-reservation.warning-title',
    defaultMessage: 'WARNING!',
    description: 'title for warning',
  },

  // COMPANY SIGNUP
  companySignup: {
    id: 'company-signup.title',
    defaultMessage: 'Company registration',
    description: 'Title for company registration',
  },
  companySignupRegisterButton: {
    id: 'company-signup.register-button',
    defaultMessage: 'Save company information',
    description: 'Title for register-button',
  },
  companySignupName: {
    id: 'company-signup.name-label',
    defaultMessage: 'Company legal name',
    description: 'Title for register-button',
  },
  companySignupNamePlaceholder: {
    id: 'company-signup.name-placeholder',
    defaultMessage: 'Company legal name',
    description: 'Placeholder for fields',
  },
  companySignupTaxName: {
    id: 'company-signup.tax-name-label',
    defaultMessage: 'Company tax name',
    description: 'Title for fields',
  },
  companySignupTaxNamePlaceholder: {
    id: 'company-signup.tax-name-placeholder',
    defaultMessage: 'Type company name here',
    description: 'Placeholder for fields',
  },
  companySignupCity: {
    id: 'company-signup.city-label',
    defaultMessage: 'City',
    description: 'Title for fields',
  },
  companySignupCityPlaceholder: {
    id: 'company-signup.city-placeholder',
    defaultMessage: 'Type company city here',
    description: 'Placeholder for fields',
  },
  companySignupZip: {
    id: 'company-signup.zip-label',
    defaultMessage: 'Zip',
    description: 'Title for fields',
  },
  companySignupZipPlaceholder: {
    id: 'company-signup.zip-placeholder',
    defaultMessage: 'Type company zip here',
    description: 'Placeholder for fields',
  },
  companySignupRegistrationNumber: {
    id: 'company-signup.reg-number-label',
    defaultMessage: 'Company registration number',
    description: 'Title for fields',
  },
  companySignupRegistrationNumberPlaceholder: {
    id: 'company-signup.reg-number-placeholder',
    defaultMessage: 'Registration Number',
    description: 'Placeholder for fields',
  },
  companySignupCountry: {
    id: 'company-signup.country-label',
    defaultMessage: 'Company country',
    description: 'Title for fields',
  },
  companySignupCountryPlaceholder: {
    id: 'company-signup.country-placeholder',
    defaultMessage: 'Type company country here',
    description: 'Placeholder for fields',
  },
  companySignupAddress: {
    id: 'company-signup.address-label',
    defaultMessage: 'Address',
    description: 'Title for fields',
  },
  companySignupAddressPlaceholder: {
    id: 'company-signup.address-placeholder',
    defaultMessage: 'Type company address here',
    description: 'Placeholder for fields',
  },
  companyRegTitle: {
    id: 'company-signup.company-title',
    defaultMessage: 'Add company / State department',
    description: 'Title for fields',
  },
  companyRegIntro: {
    id: 'company-signup.company-intro',
    defaultMessage: 'Please, fill in the form below. All fields are required.',
    description: 'Title for fields',
  },

  // SEARCH BAR
  searchBarWhere: {
    id: 'search-bar.where-label',
    defaultMessage: 'Where',
    description: 'Title for fields',
  },
  searchBarWherePlaceholder: {
    id: 'search-bar.where-placeholder',
    defaultMessage: 'Choose destination',
    description: 'Title for fields',
  },
  searchBarWhen: {
    id: 'search-bar.when-label',
    defaultMessage: 'When',
    description: 'Placeholder for fields',
  },
  searchBarWhenPlaceholder: {
    id: 'search-bar.when-placeholder',
    defaultMessage: 'Choose your date',
    description: 'Placeholder for fields',
  },
  searchBarRound: {
    id: 'search-bar.round-trip',
    defaultMessage: 'Round-trip?',
    description: 'Title for round trip',
  },
  searchBarButton: {
    id: 'search-bar.button',
    defaultMessage: 'GET TICKETS',
    description: 'Text for button',
  },

  // PROFILE SETTINGS
  profileUserCredentials: {
    id: 'profile.user-credentials-title',
    defaultMessage: 'User credentials',
    description: 'User credentials section title',
  },
  loginEmail: {
    id: 'profile.login-email-label',
    defaultMessage: 'Login / Email',
    description: 'Label for fields',
  },
  loginByIdCard: {
    id: 'profile.login-by-id-card-text',
    defaultMessage: 'Login by your Personal Identification Number (PIN)',
    description: 'Text if registered by any id card',
  },
  password: {
    id: 'profile.password-label',
    defaultMessage: 'Password',
    description: 'Label for fields',
  },
  profileChangePasswordText: {
    id: 'profile.change-password-text',
    defaultMessage: 'Click here to change password',
    description: 'Label for change password button',
  },
  profileChangePasswordSuccessText: {
    id: 'profile.change-password-success-text',
    defaultMessage: 'Your password has been changed',
    description: 'Text for successful change password',
  },
  profileRemoveAccountLabel: {
    id: 'profile.remove-account-label',
    defaultMessage: 'Remove account',
    description: 'Label for remove account field',
  },
  profileRemoveAccountText: {
    id: 'profile.remove-account-text',
    defaultMessage: 'Click here to completely remove your account',
    description: 'Label for remove account button',
  },
  profilePersonSectionText: {
    id: 'profile.person-section-text',
    defaultMessage:
      'Private customer (this information is used for invoicing and contacting to you, like sending you tickets)',
    description: 'Description for private customer section',
  },
  profileCompanySectionText: {
    id: 'profile.company-section-text',
    defaultMessage:
      "Legal customer's representative (this information is used for invoicing and contacting to you, like sending you tickets)",
    description: 'Description for company section',
  },
  profileGovCompanySectionText: {
    id: 'profile.gov-company-section-text',
    defaultMessage:
      "State structure's representative (this information is used for invoicing and contacting to you, like sending you tickets)",
    description: 'Description for state structure section',
  },
  profileAddNewCompanyTitle: {
    id: 'profile.add-new-company-title',
    defaultMessage: 'Add new company',
    description: 'Title for add new company',
  },
  name: {
    id: 'profile.name',
    defaultMessage: 'Name',
    description: 'Label for fields',
  },
  namePlaceholder: {
    id: 'profile.namePlaceholder',
    defaultMessage: 'John',
    description: 'Placeholder for fields',
  },
  phoneNr: {
    id: 'profile.phone',
    defaultMessage: 'Phone number',
    description: 'Label for fields',
  },
  legalName: {
    id: 'profile.company-name',
    defaultMessage: 'Company name',
    description: 'Label for fields',
  },
  registrationNumber: {
    id: 'profile.reg-nr',
    defaultMessage: 'Registration number',
    description: 'Label for fields',
  },
  zip: {
    id: 'profile.zip',
    defaultMessage: 'ZIP',
    description: 'Label for zip',
  },
  city: {
    id: 'profile.city',
    defaultMessage: 'City',
    description: 'Label for city',
  },
  country: {
    id: 'profile.country',
    defaultMessage: 'Country',
    description: 'Label for country',
  },
  addressLine: {
    id: 'profile.legal-addr',
    defaultMessage: 'Legal address',
    description: 'Label for fields',
  },
  yourSettings: {
    id: 'profile.settings',
    defaultMessage: 'Your settings',
    description: 'Settings tab',
  },
  savedData: {
    id: 'profile.saved-data',
    defaultMessage: 'Saved data',
    description: 'Saved data tab',
  },
  confirmedNewPassword: {
    id: 'profile.new-password-confirm',
    defaultMessage: 'Repeat new password',
    description: 'Label for second new password',
  },
  changePassword: {
    id: 'profile.button-change-password',
    defaultMessage: 'change password',
    description: 'Label for change password button',
  },
  addMore: {
    id: 'profile.button-add-more',
    defaultMessage: 'Add Company',
    description: 'Label for Add Company button',
  },
  creditLimit: {
    id: 'profile.credit-limit',
    defaultMessage: 'Credit limit:',
    description: 'Label for credit limit',
  },
  unused: {
    id: 'profile.unused',
    defaultMessage: 'Unused:',
    description: 'Label for credit limit',
  },
  daysRenew: {
    id: 'profile.renew',
    defaultMessage: 'Days until renew:',
    description: 'Label for credit limit',
  },

  // SAVED DATA
  savedDataIntro: {
    id: 'profile.saved-data-intro',
    defaultMessage:
      'Here you can find and manage all information that is stored in our system and helps to speed up your ordering process',
    description: 'Intro text at the saved data section',
  },
  surname: {
    id: 'profile.surname',
    defaultMessage: 'Surname',
    description: 'Label for surname',
  },
  sex: {
    id: 'profile.sex',
    defaultMessage: 'Sex',
    description: 'Label for sex',
  },
  ageGroup: {
    id: 'profile.ageGroup',
    defaultMessage: 'Age group',
    description: 'Label for ageGroup',
  },
  surnamePlaceholder: {
    id: 'profile.surnamePlaceholder',
    defaultMessage: 'Smith',
    description: 'Placeholder for surname field',
  },
  gender: {
    id: 'profile.gender',
    defaultMessage: 'Gender',
    description: 'Label for gender',
  },
  addInfo: {
    id: 'profile.add-info',
    defaultMessage: 'Ad. information',
    description: 'Label for additional information',
  },
  dateOfBirth: {
    id: 'profile.birthday',
    defaultMessage: 'Day of birth',
    description: 'Label for date of birth',
  },
  titlePasInfo: {
    id: 'profile.title-pas-info',
    defaultMessage: 'PASSENGERS INFORMATION',
    description: 'Label for date of birth',
  },
  titleCarInfo: {
    id: 'profile.title-car-info',
    defaultMessage: 'CARS INFORMATION',
    description: 'Label for date of birth',
  },
  addNew: {
    id: 'profile.add-new',
    defaultMessage: 'Add new',
    description: 'Label add new button',
  },

  // MODALS
  hotelIsNotAdded: {
    id: 'modal.hotel-is-not-added',
    defaultMessage: 'You cannot proceed without adding your Hotel pickup area. Please enter the name of your hotel',
    description: 'Content for hotel is not added modal',
  },
  sessionExpired: {
    id: 'modal.session-expired',
    defaultMessage: 'Your session expired, please, start over',
    description: 'Content for session expired modal',
  },
  sessionWillExpired: {
    id: 'modal.session-will-expired',
    defaultMessage: 'Your session is about to expire in 2 minutes',
    description: 'Content for session will expired modal',
  },
  extendMySession: {
    id: 'modal.extend-my-session',
    defaultMessage: 'Extend my session',
    description: 'Label for button Extend my session',
  },
  ok: {
    id: 'modal.ok',
    defaultMessage: 'ok',
    description: 'Label for ok button',
  },
  addCustomVehicleWeight: {
    id: 'modal.custom.vehicle.weight',
    defaultMessage: 'Weight change',
    description: 'Weight change',
  },
  addCustomVehicleWeightSubHeader: {
    id: 'modal.custom.vehicle.weight.header',
    defaultMessage: 'Change tonnage (weight) of vehicle (kg)',
    description: 'Change tonnage (weight) of vehicle (kg)',
  },
  proceedPayment: {
    id: 'modal.creditcard.pay',
    defaultMessage: 'Proceed',
    description: 'Proceed payment',
  },
  payWithCreditCard: {
    id: 'modal.creditcard.header',
    defaultMessage: 'Pay with a credit card',
    description: 'Pay with a credit card',
  },
  creditCardPopUpPlaceholderName: {
    id: 'modal.creditcard.placeholder.name',
    defaultMessage: 'Name on card',
    description: 'Full name input placeholder',
  },
  creditCardPopUpPlaceholderCardNumber: {
    id: 'modal.creditcard.placeholder.cardNumber',
    defaultMessage: 'Credit card number',
    description: 'Credit card number placeholder',
  },
  creditCardPopUpPlaceholderCvv: {
    id: 'modal.creditcard.placeholder.cvv',
    defaultMessage: 'CVV Number',
    description: 'CVV number',
  },

  howToEditVehicleWeight1: {
    id: 'confirmation.custom.weight.tip.one',
    defaultMessage: 'Please click on ',
    description: 'Please click on ',
  },

  howToEditVehicleWeight2: {
    id: 'confirmation.custom.weight.tip.two',
    defaultMessage: 'to change weight of added vehicle/trailer',
    description: 'to change weight of added vehicle/trailer',
  },

  // PROFILE TICKETS
  sortByTicket: {
    id: 'profile.sort-by-ticket',
    defaultMessage: 'Sort by ticket',
    description: 'Label for Sort by ticket',
  },
  sortByDate: {
    id: 'profile.sort-by-date',
    defaultMessage: 'by date',
    description: 'Label for Sort by date',
  },
  sortByDirection: {
    id: 'profile.sort-by-direction',
    defaultMessage: 'by direction',
    description: 'Label for Sort by direction',
  },
  remove: {
    id: 'profile.button-remove',
    defaultMessage: 'remove',
    description: 'Label for remove button',
  },
  save: {
    id: 'profile.button-save',
    defaultMessage: 'save',
    description: 'Label for save button',
  },
  removeConfirmTitle: {
    id: 'profile.dialog-remove-title',
    defaultMessage: 'Confirm remove',
    description: 'Label for confirm dialog title',
  },
  removeConfirmQuestion: {
    id: 'profile.dialog-remove-question',
    defaultMessage: 'Do you really want to remove this item?',
    description: 'Label for confirm dialog question',
  },
  view: {
    id: 'profile.button-view',
    defaultMessage: 'view',
    description: 'Label for view',
  },
  edit: {
    id: 'profile.button-edit',
    defaultMessage: 'edit',
    description: 'Label for edit',
  },
  download: {
    id: 'profile.button-download',
    defaultMessage: 'download',
    description: 'Label for download',
  },
  orderSame: {
    id: 'profile.button-order-same',
    defaultMessage: 'order same',
    description: 'Label for order same',
  },
  youHaveNoTickets: {
    id: 'profile.no-tickets',
    defaultMessage: 'Yout have no tickets.',
    description: 'Label for no tickets case',
  },
  bookItNow: {
    id: 'profile.book-it-now',
    defaultMessage: 'Book it now.',
    description: 'Label for book it now',
  },
  viewAll: {
    id: 'profile.view-all',
    defaultMessage: 'View all previous purchases',
    description: 'Label for View all previous purchases',
  },
  hideAll: {
    id: 'profile.hide-all',
    defaultMessage: 'Hide previous purchases',
    description: 'Label for Hide previous purchases',
  },
  includeCanc: {
    id: 'profile.include-cancelled',
    defaultMessage: 'Include cancelled e-tickets',
    description: 'Label for Include cancelled e-tickets checkbox',
  },

  // PROFILE USERS

  users: {
    id: 'profile.users',
    defaultMessage: 'Users',
    description: 'Label for Profile users tab',
  },
  chooseCompany: {
    id: 'profile.choose-company',
    defaultMessage: 'Choose company:',
    description: 'Label for Choose company:',
  },
  chooseUser: {
    id: 'profile.choose-user',
    defaultMessage: 'Choose user:',
    description: 'Label for chooseUser',
  },
  monthlyCredit: {
    id: 'profile.monthly-credit',
    defaultMessage: 'Monthly credit free / limit:',
    description: 'Label for monthlyCredit',
  },
  you: {
    id: 'profile-users.you',
    defaultMessage: 'You',
    description: 'Label for you',
  },
  id: {
    id: 'profile-users.id',
    defaultMessage: 'ID',
    description: 'Label for id',
  },
  idCode: {
    id: 'profile-users.id-code',
    defaultMessage: 'ID code',
    description: 'Label for idCode',
  },
  phone: {
    id: 'profile-users.phone',
    defaultMessage: 'Phone',
    description: 'Label for phone',
  },
  phonePlaceholder: {
    id: 'profile-users.phonePlaceholder',
    defaultMessage: '+3 555 555 555',
    description: 'Placeholder for phone',
  },
  usersEmail: {
    id: 'profile-users.email',
    defaultMessage: 'E-mail',
    description: 'Label for e-mail',
  },
  usersViewTickets: {
    id: 'profile-users.view-tickets',
    defaultMessage: "VIEW USER'S TICKETS",
    description: 'Label for usersViewTickets',
  },
  usersActivate: {
    id: 'profile-users.activate',
    defaultMessage: 'Activate',
    description: 'Label for usersActivate',
  },
  inviteUser: {
    id: 'profile-users.add-new',
    defaultMessage: 'Add new user',
    description: 'Label for inviteUser',
  },
  usersDeactivate: {
    id: 'profile-users.deactivate',
    defaultMessage: 'Deactivate',
    description: 'Label for usersDeactivate',
  },
  usersStatusInactive: {
    id: 'profile-users.status.inactive',
    defaultMessage: 'Inactive',
    description: 'Label for usersStatusInactive',
  },
  usersStatusInvited: {
    id: 'profile-users.status.invited',
    defaultMessage: 'Invited',
    description: 'Label for usersStatusInvited',
  },
  usersInvitationSent: {
    id: 'profile-users.status.invitation.sent',
    defaultMessage: 'INVITATION SENT',
    description: 'Label for usersInvitationSent',
  },
  usersInvitationResend: {
    id: 'profile-users.status.invitation.resend',
    defaultMessage: 'Resend invitation',
    description: 'Label for usersInvitationResend',
  },
  usersInvitationResendSuccess: {
    id: 'profile-users.status.invitation.resend-success',
    defaultMessage: 'Invitation resent',
    description: 'Label for usersInvitationResendSuccess',
  },
  usersInvitationSend: {
    id: 'profile-users.status.invitation.send',
    defaultMessage: 'Send invitation',
    description: 'Label for usersInvitationSend',
  },
  commonUser: {
    id: 'profile-users.status.common',
    defaultMessage: 'Common',
    description: 'Label for commonUser',
  },
  mainUser: {
    id: 'profile-users.status.main',
    defaultMessage: 'Main',
    description: 'Label for mainUser',
  },
  sendInvitation: {
    id: 'profile-users.send.invitation',
    defaultMessage: 'Send invitation to new user',
    description: 'Label for sendInvitation',
  },
  howItWorks: {
    id: 'profile-users.send.invitation.process.howto',
    defaultMessage: 'How it works?',
    description: 'Label for howItWorks',
  },
  invitationProcessStep1: {
    id: 'profile-users.send.invitation.process.step1',
    defaultMessage:
      "Step 1 - You're <b>sending invitation</b> to new user's email (you're here now). Please, double-check that you typed email correctly!",
    description: 'Label for invitationProcessStep1',
  },
  invitationProcessStep2: {
    id: 'profile-users.send.invitation.process.step2',
    defaultMessage:
      'Step 2 - New user receives the email and <b>clicks to special link</b> to be added as your new user. Then new <b>user fills some data</b> about himself, as Name, phone number etc.',
    description: 'Label for invitationProcessStep2',
  },
  commonUserActionNotice: {
    id: 'profile-users.action.notice',
    defaultMessage: 'PLEASE, BE NOTED, THAT:',
    description: 'Label for commonUserActionNotice',
  },
  commonUserActionSoundsGood: {
    id: 'profile-users.action.sounds.good',
    defaultMessage: "Sounds good, I'm agree",
    description: 'Label for commonUserActionSoundsGood',
  },
  activateUser: {
    id: 'profile-users.action.activate',
    defaultMessage: 'Activate user',
    description: 'Label for activateUser',
  },
  activateUserPoint1: {
    id: 'profile-users.action.activate.point1',
    defaultMessage:
      "When activated user will be able to use company's credit amount or buy tickets to the company's name.",
    description: 'Label for activateUserPoint1',
  },
  activateUserPoint2: {
    id: 'profile-users.action.activate.point2',
    defaultMessage:
      'All information about purchased tickets will be accessible for you, as well as editing or deleting operations.',
    description: 'Label for activateUserPoint2',
  },
  activateUserPoint3: {
    id: 'profile-users.action.activate.point3',
    defaultMessage: 'You can always deactivate user if needed.',
    description: 'Label for activateUserPoint3',
  },
  deactivateUser: {
    id: 'profile-users.action.deactivate',
    defaultMessage: 'Deactivate user',
    description: 'Label for activateUser',
  },
  deactivateUserPoint1: {
    id: 'profile-users.action.deactivate.point1',
    defaultMessage:
      "This user will be marked as inactive in your users list, and when you're sure, you may completely remove it from the list.",
    description: 'Label for deactivateUserPoint1',
  },
  deactivateUserPoint2: {
    id: 'profile-users.action.deactivate.point2',
    defaultMessage: "This user will not be able to use company's credit amount or buy tickets to the company's name.",
    description: 'Label for deactivateUserPoint2',
  },
  deactivateUserPoint3: {
    id: 'profile-users.action.deactivate.point3',
    defaultMessage:
      'All information about purchased tickets will be still accessible both from you and this user sides, as well as delete operations. It means that if one of you will prefer to delete old ticked, both of you may lose access to it.',
    description: 'Label for deactivateUserPoint3',
  },
  deactivateUserPoint4: {
    id: 'profile-users.action.deactivate.point4',
    defaultMessage: 'You can always re-activate user back again.',
    description: 'Label for deactivateUserPoint4',
  },
  removeUserIsSerious: {
    id: 'profile-users.action.remove.is.serious',
    defaultMessage: 'NOW THIS IS SERIOUS:',
    description: 'Label for removeUserIsSerious',
  },
  saveUser: {
    id: 'profile-users.action.save',
    defaultMessage: 'Save',
    description: 'Label for saveUser',
  },
  removeUserPoint1: {
    id: 'profile-users.action.remove.point1',
    defaultMessage: "You're going to lost ALL information about this user's transactions",
    description: 'Label for removeUserPoint1',
  },
  removeUserPoint2: {
    id: 'profile-users.action.remove.point2',
    defaultMessage: 'This operation is irreversible.',
    description: 'Label for removeUserPoint2',
  },
  removeIKnow: {
    id: 'profile-users.action.remove.i.know',
    defaultMessage: "I know what I'm doing!",
    description: 'Label for removeIKnow',
  },
  createCorporateAccount: {
    id: 'corporate-account.create',
    defaultMessage: 'Create Corporate Account',
    description: 'Label for createCorporateAccount',
  },
  corporateJoinTokenNotAvailable: {
    id: 'corporate-account.join-token-not-available',
    defaultMessage:
      'Autentification of Corporate account is not valid more. Please contact your Manager in case of any additional questions.',
    description: 'Label for corporateJoinTokenNotAvailable',
  },
  corporateJoinPersonalIdNotValid: {
    id: 'corporate-account.join-personal-id-not-valid',
    defaultMessage:
      'ID doesn’t match to join to corporate account. Make sure you are using correct credentials or try another authentication method.',
    description: 'Label for corporateJoinPersonalIdNotValid',
  },
  corporatePhoneNumber: {
    id: 'corporate-account.phone-number',
    defaultMessage: 'Phone number',
    description: 'label for phone number field',
  },
  loginViaETProviders: {
    id: 'corporate-account.login-et-providers',
    defaultMessage: 'Please, login using one of three methods, listed below.',
    description: 'Label for loginViaETProviders',
  },
  fillInformation: {
    id: 'corporate-account.fill.information',
    defaultMessage: 'Please, fill in following information:',
    description: 'Label for fillInformation',
  },
  someImportantThings: {
    id: 'corporate-account.some.important.things',
    defaultMessage: 'Some important things:',
    description: 'Label for someImportantThings',
  },
  createCorporateAccountTip1: {
    id: 'corporate-account.create.tip1',
    defaultMessage:
      "<b>If you already have an account</b> on our website, and provide here the same email address, new corporate profile will be added to your existing account, you'll still have the possibility to buy tickets as you used to do, but a new feature will be added and you'll be able to pay your tickets with your company account.",
    description: 'Label for createCorporateAccountTip1',
  },
  createCorporateAccountTip2: {
    id: 'corporate-account.create.tip2',
    defaultMessage:
      "<b>If this is first time you create account</b> on our website, you'll receive a password setup email to get into your account by email in the future. You will be able also to use different Sign-in options like the ID card, Mobiil-ID, Smart-ID and social networks. Within this account you'll be able to pay your tickets with your company account and in usual way as an individual person.",
    description: 'Label for createCorporateAccountTip2',
  },

  // PAY CONFIRM
  carRegNr: {
    id: 'customer-info.car-reg-nr',
    defaultMessage: 'Vehicle Nr.',
    description: 'Label Car reg. nr',
  },
  trailerRegNr: {
    id: 'customer-info.trailer-reg-nr',
    defaultMessage: 'Trailer Nr.',
    description: 'Label Trailer reg. nr',
  },
  carWidth: {
    id: 'customer-info.car-width',
    defaultMessage: 'Width',
    description: 'Label car width',
  },
  carLength: {
    id: 'customer-info.car-length',
    defaultMessage: 'Length',
    description: 'Label car width',
  },
  carHeight: {
    id: 'customer-info.car-height',
    defaultMessage: 'Height',
    description: 'Label car width',
  },
  carWeight: {
    id: 'customer-info.car-weight',
    defaultMessage: 'Weight',
    description: 'Label car width',
  },
  carWidthS: {
    id: 'customer-info.car-width-small',
    defaultMessage: 'Less than 2m',
    description: 'Label car width',
  },
  carWidthM: {
    id: 'customer-info.car-width-medium',
    defaultMessage: '2...2.5m',
    description: 'Label car width',
  },
  carWidthL: {
    id: 'customer-info.car-width-large',
    defaultMessage: 'More than 2.5m',
    description: 'Label car width more than 2.5 m',
  },
  carWidthMSVI: {
    id: 'customer-info.car-width-medium-svi',
    defaultMessage: '2...2.6m',
    description: 'Label car width',
  },
  carWidthLSVI: {
    id: 'customer-info.car-width-large-svi',
    defaultMessage: 'More than 2.6m',
    description: 'Label car width more than 2.5 m',
  },
  carWidthOver2: {
    id: 'customer-info.car-width-over-2',
    defaultMessage: 'More than 2m',
    description: 'Label car width more than 2 m',
  },
  carHeightS: {
    id: 'customer-info.car-height-small',
    defaultMessage: 'Less than 4m',
    description: 'Label car height',
  },
  carHeightL: {
    id: 'customer-info.car-height-large',
    defaultMessage: 'More than 4m',
    description: 'Label car height',
  },
  carWeightS: {
    id: 'customer-info.car-weight-small',
    defaultMessage: 'Less than 1t',
    description: 'Label car weight small',
  },
  totalToPay: {
    id: 'pay-confirm.total-to-pay',
    defaultMessage: 'Total to pay',
    description: 'Label fot total sum to pay',
  },
  ticketType: {
    id: 'confirmation.title.ticket.type',
    defaultMessage: 'Ticket Type',
    description: 'Label for total in ticket param',
  },
  ticketQtty: {
    id: 'confirmation.title.ticket.qtty',
    defaultMessage: 'qty',
    description: 'Label for qty in ticket param',
  },
  ticketPrice: {
    id: 'confirmation.title.ticket.price',
    defaultMessage: 'price',
    description: 'Label for price in ticket param',
  },
  ticketSubtotal: {
    id: 'confirmation.title.ticket.subtotal',
    defaultMessage: 'subtotal',
    description: 'Label for subtotal in ticket param',
  },
  editTrip: {
    id: 'confirmation.action.button.edit',
    defaultMessage: 'Edit',
    description: 'Label for edit trip button',
  },
  removeTrip: {
    id: 'confirmation.action.button.remove',
    defaultMessage: 'Remove',
    description: 'Label for edit trip button',
  },

  // SALES STATIC
  newsPageTitle: {
    id: 'sales-static.news-page-title',
    defaultMessage: 'News',
    description: 'label for news page title',
  },
  nextPagination: {
    id: 'sales-static.next-pagination',
    defaultMessage: 'next',
    description: 'label for next',
  },
  previousPagination: {
    id: 'sales-static.previous-pagination',
    defaultMessage: 'previous',
    description: 'label for previous',
  },
  staticPageNotFoundTitle: {
    id: 'sales-static.page-not-found-title',
    defaultMessage: 'Page Not Found',
    description: 'Title for foge not found case',
  },
  staticPageNotFoundDescription: {
    id: 'sales-static.page-not-found-description',
    defaultMessage: 'This page does not exist',
    description: 'Description for foge not found case',
  },

  // TICKET-EDIT
  applyChanges: {
    id: 'ticket-edit.apply-changes',
    defaultMessage: 'Apply changes',
    description: 'label for aplly changes button',
  },
  modaltrailer: {
    id: 'ticket-edit.edit-trailer',
    defaultMessage: 'EDIT TRAILER INFORMATION',
    description: 'label for EDIT TRAILER INFORMATION',
  },
  modaltickets: {
    id: 'ticket-edit.edit-ticket-type',
    defaultMessage: 'EDIT Ticket Type',
    description: 'label for EDIT Ticket Type',
  },
  modalpassenger: {
    id: 'ticket-edit.edit-passenger',
    defaultMessage: 'EDIT passenger info',
    description: 'label for EDIT passenger info',
  },
  trailerNr: {
    id: 'ticket-edit.trailer-nr',
    defaultMessage: 'Trailer nr.',
    description: 'label for trailer number',
  },
  trailerWeight: {
    id: 'ticket-edit.trailer-weight',
    defaultMessage: 'Trailer weight',
    description: 'label for trailer weight',
  },
  saveChanges: {
    id: 'ticket-edit.save-changes',
    defaultMessage: 'Save changes',
    description: 'label for save changes',
  },
  clickTo: {
    id: 'ticket-edit.click-to',
    defaultMessage: 'Click to',
    description: 'label for click to',
  },
  payTheDifference: {
    id: 'ticket-edit.pay-the-difference',
    defaultMessage: 'pay the difference',
    description: 'label for pay the difference',
  },
  canselAndBack: {
    id: 'ticket-edit.cancel',
    defaultMessage: 'cancel edits and back to ticket',
    description: 'label for cancel edits',
  },
  cancelAndBackLaak: {
    id: 'ticket-edit.cancel-laak',
    defaultMessage: 'Cancel edits and back to booked reservation',
    description: 'Cancel edits and back to booked reservation',
  },
  difToPay: {
    id: 'ticket-edit.dif-to-pay',
    defaultMessage: 'Difference to pay',
    description: 'label for Difference to pay',
  },
  oldTotal: {
    id: 'ticket-edit.old-total',
    defaultMessage: 'Old total',
    description: 'label for old total',
  },
  newTotal: {
    id: 'ticket-edit.new-total',
    defaultMessage: 'New total',
    description: 'label for new total',
  },
  modalman: {
    id: 'ticket-edit.edit-customer',
    defaultMessage: 'EDIT CUSTOMER INFO',
    description: 'label for EDIT CUSTOMER INFO',
  },
  modalcar: {
    id: 'ticket-edit.edit-car',
    defaultMessage: 'EDIT CARINFO',
    description: 'label for EDIT car info',
  },
  modaldatetime: {
    id: 'ticket-edit.edit-datetime',
    defaultMessage: 'EDIT DATE AND TIME',
    description: 'label for EDIT DATE AND TIME',
  },
  modalattentionMode: {
    id: 'ticket-edit.modal-confirm',
    defaultMessage: 'PLEASE, CONFIRM YOUR DECISION',
    description: 'label for PLEASE, CONFIRM YOUR DECISION',
  },
  modalreservationExpired: {
    id: 'modal.session-expired.header',
    defaultMessage: 'Session expiration',
    description: 'label for expiration modal header',
  },
  modalreservationWannaExpired: {
    id: 'modal.session-will-expired.header',
    defaultMessage: 'Session expiration',
    description: 'label for expiration with question modal header',
  },
  modalconfirmation: {
    id: 'vehicle.no.driver.confirmation.header',
    defaultMessage: 'Warning!',
    description: 'label for confirmation modal header',
  },
  modaldifferentLicensePlateOnRoundTrip: {
    id: 'vehicle.differentLicensePlate.header',
    defaultMessage: 'Warning!',
    description: 'label for round trip modal header',
  },
  modaldriverForReserveWithLocalVehicleOnly: {
    id: 'vehicle.local.driver.confirmation.header',
    defaultMessage: 'Warning!',
    description: 'label for driver modal header',
  },
  cancelOparation: {
    id: 'ticket-edit.cancel-oparation',
    defaultMessage: 'Cancel operation',
    description: 'label for Cancel operation',
  },
  removeUser: {
    id: 'ticket-edit.remove-user',
    defaultMessage: 'Remove user',
    description: 'label for remove user',
  },
  iamSure: {
    id: 'ticket-edit.i-am-sure',
    defaultMessage: "Yes, I'm sure I want to completely remove this user.",
    description: 'label for confirm modal checkbox',
  },
  ticketDataChanged: {
    id: 'ticket-edit.ticket-data-changed',
    defaultMessage: 'Please note, some data has been changed or deleted',
    description: 'label for ticket data changed notification',
  },

  // RESERVATION FAILURE
  ohsnap: {
    id: 'ticket-error.ohsnap',
    defaultMessage: 'Oh, snap :-(',
    description: 'label for Oh, snap',
  },
  yourReservation: {
    id: 'ticket-error.yourReservation',
    defaultMessage: 'Your reservation',
    description: 'label for your reservation',
  },
  bookingProcessError: {
    id: 'ticket-error.bookingProcessError',
    defaultMessage: 'There is error occured during booking process:',
    description: 'label for bookingProcessError',
  },
  reservationLockedError: {
    id: 'ticket-error.reservation.locked.error',
    defaultMessage: 'Sorry! An error occurred during the booking process. Please start the booking process again',
    description: 'Sorry! An error occurred during the booking process. Please start the booking process again',
  },
  reservationNotFoundError: {
    id: 'ticket-error.reservation.not.found.error',
    defaultMessage: 'Reservation Not Found! Solution is to start over reservation process.',
    description: 'Sorry! An error occurred during the booking process. Please start the booking process again',
  },
  reservationInvalidStatusError: {
    id: 'ticket-error.reservation.invalid.error',
    defaultMessage: 'Reservation Not Found! Solution is to start over reservation process.',
    description: 'Sorry! An error occurred during the booking process. Please start the booking process again',
  },
  paymentCCInvalidStatusError: {
    id: 'ticket-error.paymentCCInvalidStatusError',
    defaultMessage:
      'Unfortunately your payment was rejected due to some fraud checks defined by the Issuer. Please try again or use another card',
    description: 'label for bookingProcessError',
  },
  reservationNotExist: {
    id: 'ticket-error.resNotExist',
    defaultMessage: 'Reservation Not Found',
    description: 'label for solutionStart',
  },
  reservationPromotion: {
    id: 'reservation.including.promotion',
    defaultMessage: 'including promotion:',
    description: 'including promotion:',
  },
  solutionStart: {
    id: 'ticket-error.solutionStart',
    defaultMessage: 'Solution is to start over reservation process.',
    description: 'label for solutionStart',
  },

  // REFUND
  tripNonRefundable: {
    id: 'reservation-refund.non-refundable',
    defaultMessage:
      'This trip was cancelled. You may <action>change</action> the date/time of your trip. <b>Reservation rent is non-refundable on this line.</b>',
    description: 'label for tripWasCancelled',
  },
  tripCanRefundable: {
    id: 'reservation-refund.can-refundable',
    defaultMessage:
      'This trip was cancelled. You may <action>change</action> the date/time of your trip or <action>cancel</action> it entirely and get 100% refund',
    description: 'label for tripCanRefundable',
  },
  tripCancelledWithRefundDisabled: {
    id: 'reservation-refund.refund-disabled',
    defaultMessage: 'This trip was cancelled. You may change the date/time of your trip.',
    description: 'label for tripCancelledWithRefundDisabled',
  },
  bookedTripCancelled: {
    id: 'reservation-refund.booked-trip-cancelled',
    defaultMessage: 'This trip was cancelled.',
    description: 'label for bookedTripCancelled',
  },
  tripImpossibleRefundable: {
    id: 'reservation-refund.impossible-refundable',
    defaultMessage:
      'It is not possible to change or cancel e-tickets because this possibility is no longer valid ({days} days passed).',
    description: 'label for tripImpossibleRefundable',
  },
  cancelRoute: {
    id: 'reservation-refund.cancel-route',
    defaultMessage: 'Cancel route',
    description: 'label for cancelRoute',
  },
  cancelRouteComment: {
    id: 'reservation-refund.cancel-route-comment',
    defaultMessage: 'Please choose one or both routes to cancel the tickets.',
    description: 'label for cancelRouteComment',
  },
  cancelRouteCommentLaak: {
    id: 'reservation-refund.cancel-route-comment-laak',
    defaultMessage: 'Please choose one or both routes to cancel the booking.',
    description: 'Please choose one or both routes to cancel the booking.',
  },
  cancelAndRemoveTicket: {
    id: 'reservation-refund.cancel-remove-title',
    defaultMessage: 'Cancel and remove E-ticket nr.',
    description: 'label for cancelAndRemoveTicket',
  },
  cancelAndRemoveTicketLaak: {
    id: 'reservation-refund.cancel-remove-title-laak',
    defaultMessage: 'Cancel and remove Booking nr.',
    description: 'Cancel and remove Booking nr.',
  },
  amountPaid: {
    id: 'reservation-refund.amount-paid',
    defaultMessage: "Amount you've paid:",
    description: 'label for amountPaid',
  },
  percentRefund: {
    id: 'reservation-refund.percent-refund',
    defaultMessage: 'Percent of refund:',
    description: 'label for percentRefund',
  },
  amountRefund: {
    id: 'reservation-refund.amount-refund',
    defaultMessage: 'Amount to be refund:',
    description: 'label for amountRefund',
  },
  amountRefundTip: {
    id: 'reservation-refund.amount-refund-tip',
    defaultMessage:
      '* - will be refunded to you by the means on Maksekeskus, using same payment gateway that you chosen while purchasing the ticket(s).',
    description: 'label for amountRefundTip',
  },
  amountRefundTalAegTal: {
    id: 'reservation-refund.amount-refund-tal-aeg-tal',
    defaultMessage: 'Cancellation or change fee is 3.20 euro, therefore your refund amount is 0.00 euro.',
    description: 'label for amountRefundTalAegTal',
  },
  refundAgreement: {
    id: 'reservation-refund.refund-agreement',
    defaultMessage:
      "Yes, I want to cancel and remove this E-ticket and I'm agree to receive above mentioned amount as per Service Provider Agreement.",
    description: 'label for refundAgreement',
  },
  refundAgreementLaak: {
    id: 'reservation-refund.refund-agreement-laak',
    defaultMessage: 'Yes, I want to cancel and remove this booking.',
    description: 'Yes, I want to cancel and remove this booking.',
  },
  refundAgreementRin: {
    id: 'reservation-refund.refund-agreement-rin',
    defaultMessage: 'Yes, I want to cancel and remove this booking within Service Provider Agreement.',
    description: 'Yes, I want to cancel and remove this booking within Service Provider Agreement.',
  },
  removeTicket: {
    id: 'reservation-refund.refund-remove-ticket',
    defaultMessage: 'Cancel and remove this E-ticket',
    description: 'label for removeTicket',
  },
  removeTicketLaak: {
    id: 'reservation-refund.refund-remove-ticket-laak',
    defaultMessage: 'Cancel and remove this booking',
    description: 'label for removeTicket',
  },
  changeMyMind: {
    id: 'reservation-refund.change-my-mind',
    defaultMessage: "No, I've change my mind",
    description: 'label for changeMyMind',
  },
  keepTicket: {
    id: 'reservation-refund.keep-ticket',
    defaultMessage: 'I want to keep this ticket',
    description: 'label for keepTicket',
  },
  keepTicketLaak: {
    id: 'reservation-refund.keep-ticket-laak',
    defaultMessage: 'I want to keep this booking',
    description: 'label for keepTicket',
  },
  cancelled: {
    id: 'reservation-refund.sail-cancelled',
    defaultMessage: 'cancelled',
    description: 'label for cancelled',
  },
  ticketWasRefundHeader: {
    id: 'reservation-refund.was-refund-header',
    defaultMessage: 'Cancel and remove E-ticket nr. <b>{reservationId}</b>',
    description: 'label for ticketWasRefund',
  },
  ticketWasRefund: {
    id: 'reservation-refund.was-refund',
    defaultMessage: 'Your ticket nr. <b>{reservationId}</b> is successfully cancelled / deleted',
    description: 'label for ticketWasRefund',
  },
  ticketWasRefundLaak: {
    id: 'reservation-refund.was-refund-laak',
    defaultMessage: 'Your Booking nr. <b>{reservationId}</b> is successfully cancelled / deleted',
    description: 'label for ticketWasRefund',
  },
  ticketWasRefundAfterEdit: {
    id: 'reservation-edit-refund.was-refund',
    defaultMessage: 'Your edits to ticket nr. <b>{reservationId}</b> are successfully saved',
    description: 'label for ticketWasRefund after edit',
  },
  ticketWasRefundTip: {
    id: 'reservation-refund.was-refund-tip',
    defaultMessage:
      'Amount of <b>{refundAmount}</b> will be returned to you by the means on Maksekeskus, using same payment gateway that you chosen while purchasing the ticket(s)',
    description: 'label for ticketWasRefundTip',
  },
  ticketWasRefundTipAfterEdit: {
    id: 'reservation-refund.was-refund-tip-edit',
    defaultMessage:
      'Difference in price (<b>{refundAmount}</b>) will be returned to you by the means on Maksekeskus, using same payment gateway that you chosen while purchasing the ticket(s)',
    description: 'label for ticketWasRefundTip after edit',
  },
  ticketWasRefundOneSail: {
    id: 'reservation-refund.was-refund-one-sail',
    defaultMessage:
      'Your <b>{routeLegTitle}</b> trip on <b>{departureAt}</b> (ticket nr. {reservationId}) is successfully cancelled / deleted.',
    description: 'label for ticketWasRefundOneSail',
  },
  ticketWasRefundOneSailLaak: {
    id: 'reservation-refund.was-refund-one-sail-laak',
    defaultMessage:
      'Your <b>{routeLegTitle}</b> trip on <b>{departureAt}</b> (booking nr. {reservationId}) is successfully cancelled / deleted.',
    description: 'label for ticketWasRefundOneSail',
  },
  mayClose: {
    id: 'reservation-refund.may-close',
    defaultMessage: 'You may close this window now.',
    description: 'label for mayClose',
  },
  backToTickets: {
    id: 'reservation-refund.back-to-tickets',
    defaultMessage: 'back to your tickets',
    description: 'label for backToTickets',
  },
  editTicketNr: {
    id: 'reservation-edit.edit-ticket-nr',
    defaultMessage: 'Edit ticket nr.',
    description: 'label for Edit ticket nr.',
  },
  editBookingNr: {
    id: 'reservation-edit.edit-ticket-nr-laak',
    defaultMessage: 'Edit booking nr.',
    description: 'Edit booking nr.',
  },
  warningText1Part1: {
    id: 'closed-sails.text-1-part1',
    defaultMessage: 'The online ticket sale is closed. It is less than 2 hours until the departure.',
    description: 'label for closed tickets',
  },
  warningText1Part2: {
    id: 'closed-sails.text-1-part2',
    defaultMessage: 'The tickets can be bought on board the ferry if there is availability.',
    description: 'label for closed tickets',
  },
  warningText2forLines1: {
    id: 'closed-sails.text-2-1',
    defaultMessage:
      'On the regular trips, 50 passenger tickets and 30 line meters of the deck will be sold from the general queue in the harbour before the departure.',
    description: 'label for closed tickets',
  },
  noteAboutTicketPart1: {
    id: 'choose-sail.out-of-stock-part1',
    defaultMessage: 'Pre-bookable capacity is sold out.',
    description: 'label for closed tickets',
  },
  noteAboutTicketPart2Lines1: {
    id: 'choose-sail.out-of-stock-part2-lines1',
    defaultMessage: '?',
    description: 'label for closed tickets',
  },
  noteAboutTicketPart3: {
    id: 'choose-sail.out-of-stock-part3',
    defaultMessage: 'no more tickets for locals available.',
    description: 'label for closed tickets',
  },
  noteAboutTicketPart4: {
    id: 'choose-sail.out-of-stock-part4',
    defaultMessage: '( ) - available tickets for local passengers.',
    description: 'label for closed tickets',
  },
  signUpSuccess: {
    id: 'auth.signup-success',
    defaultMessage:
      'An Activation link has been sent to your e-mail. Once you verify your account through the email link you can finish setting up your online account.',
    description: 'label for successful signup',
  },
  checkInbox: {
    id: 'auth.check-inbox',
    defaultMessage: 'Please, check your inbox.',
    description: 'label for Please, check your inbox.',
  },
  signupWithEmail: {
    id: 'auth.signup-with-email',
    defaultMessage: 'SIGNUP WITH EMAIL',
    description: 'title for signup form',
  },
  signupWithIdCard: {
    id: 'auth.signup-with-id-card',
    defaultMessage: 'SIGNUP WITH ID-CARD',
    description: 'title for signup form',
  },
  signupWithMobileId: {
    id: 'auth.signup-with-mobile-id',
    defaultMessage: 'SIGNUP WITH MOBILE-ID',
    description: 'title for signup form',
  },
  signupWithSmartId: {
    id: 'auth.signup-with-smart-id',
    defaultMessage: 'SIGNUP WITH SMART-ID',
    description: 'title for signup form',
  },
  signupWithFacebook: {
    id: 'auth.signup-with-facebook',
    defaultMessage: 'SIGNUP WITH FACEBOOK',
    description: 'title for signup form',
  },
  signupWithGoogle: {
    id: 'auth.signup-with-google',
    defaultMessage: 'SIGNUP WITH GOOGLE',
    description: 'title for signup form',
  },
  signupStatusNEW: {
    id: 'auth.signup-status-new',
    defaultMessage: 'NEW',
    description: 'signup status',
  },
  signupStatusACTIVE: {
    id: 'auth.signup-status-active',
    defaultMessage: 'ACTIVE',
    description: 'signup status',
  },
  signupStatusINACTIVE: {
    id: 'auth.signup-status-inactive',
    defaultMessage: 'INACTIVE',
    description: 'signup status',
  },
  signupStatusDELETED: {
    id: 'auth.signup-status-deleted',
    defaultMessage: 'DELETED',
    description: 'signup status',
  },
  signinWithEmail: {
    id: 'auth.signin-with-email',
    defaultMessage: 'SIGNIN WITH EMAIL',
    description: 'title for signin form',
  },
  signinWithIdCard: {
    id: 'auth.signin-with-id-card',
    defaultMessage: 'SIGNIN WITH ID-CARD',
    description: 'title for signin form',
  },
  signinWithMobileId: {
    id: 'auth.signin-with-mobile-id',
    defaultMessage: 'SIGNIN WITH MOBILE-ID',
    description: 'title for signin form',
  },
  signinWithSmartId: {
    id: 'auth.signin-with-smart-id',
    defaultMessage: 'SIGNIN WITH SMART-ID',
    description: 'title for signin form',
  },
  signinWithFacebook: {
    id: 'auth.signin-with-facebook',
    defaultMessage: 'SIGNIN WITH FACEBOOK',
    description: 'title for signin form',
  },
  signinWithGoogle: {
    id: 'auth.signin-with-google',
    defaultMessage: 'SIGNIN WITH GOOGLE',
    description: 'title for signin form',
  },
  // Vehicles client card request form
  submit: {
    id: 'vccf.submit',
    defaultMessage: 'Submit',
    description: 'label for submit button',
  },

  address: {
    id: 'vccf.address',
    defaultMessage: 'Address',
    description: 'label for address field',
  },

  addressPlaceholder: {
    id: 'vccf.addressPlaceholder',
    defaultMessage: 'Loigu 1-3',
    description: 'Placeholderfor address field',
  },

  postal_code: {
    // eslint-disable-line
    id: 'vccf.postal_code',
    defaultMessage: 'Postal code',
    description: 'label for postal code',
  },

  postal_codePlaceholder: {
    // eslint-disable-line
    id: 'vccf.postal_codePlaceholder',
    defaultMessage: '92028',
    description: 'Placeholder for postal code',
  },

  county: {
    id: 'vccf.county',
    defaultMessage: 'County',
    description: 'label for county field',
  },
  countyPlaceholder: {
    id: 'vccf.countyPlaceholder',
    defaultMessage: 'Harju',
    description: 'Placeholder for county field',
  },
  card_type: {
    // eslint-disable-line
    id: 'vccf.card_type',
    defaultMessage: 'Type',
    description: 'label for card type field',
  },
  card_typePlaceholder: {
    // eslint-disable-line
    id: 'vccf.card_typePlaceholder',
    defaultMessage: 'Type',
    description: 'label for card type field',
  },
  deliver_to: {
    // eslint-disable-line
    id: 'vccf.deliver_to',
    defaultMessage: 'Deliver to',
    description: 'label for deliver to field',
  },

  deliver_toPlaceholdeer: {
    // eslint-disable-line
    id: 'vccf.deliver_toPlaceholder',
    defaultMessage: 'Jõe põik 99',
    description: 'label for deliver to field',
  },

  vehicle_nr: {
    // eslint-disable-line
    id: 'vccf.vehicle_nr',
    defaultMessage: 'Vehicle reg. nr',
    description: 'label for field',
  },
  vehicle_nrPlaceholder: {
    // eslint-disable-line
    id: 'vccf.vehicle_nrPlaceholder',
    defaultMessage: '123AAA',
    description: 'label for field',
  },

  reg_certificate: {
    // eslint-disable-line
    id: 'vccf.reg_certificate',
    defaultMessage: 'Registration certificate',
    description: 'label for field',
  },

  fileField: {
    id: 'common-fields.file-field',
    defaultMessage: 'Browse',
    description: 'button text for file input',
  },

  fileFieldMaxSizeError: {
    id: 'common-fields.max-file-size-error',
    defaultMessage: 'file is too big',
    description: 'error text',
  },

  vccfFileMaxSizeWarn: {
    id: 'vccf.file-max-size-warn',
    defaultMessage: 'Only jpg, jpeg, png or pdf formats. 2mb max file size',
    description: 'button text for file input',
  },
  vccfSuccessMsg: {
    id: 'vccf.success-msg',
    defaultMessage: 'Thank you! The request has been submitted for review.',
    description: 'message on success submit',
  },
  sending: {
    id: 'vccf.sending',
    defaultMessage: 'Sending...',
    description: 'message on button while submitting',
  },
  month: {
    id: 'common.month',
    defaultMessage: 'month',
    description: 'month',
  },
  vehicleWithoutDriverConfirm: {
    id: 'vehicle.no.driver.confirmation.text',
    defaultMessage: 'Are you sure the vehicle is traveling without a driver?',
    description:
      'Message shown to the user to confirm that one wants to proceed to payment for the reservation with vehicle but no human passengers',
  },
  driverForReserveOnlyConfirm: {
    id: 'vehicle.local.driver.confirmation.text',
    defaultMessage: 'Adult ticket can be added only with local company vehicle',
    description: 'Message shown to the user to confirm that adult ticket can be added only with local vehicle',
  },
  vehicleWithoutDriverConfirmYes: {
    id: 'vehicle.no.driver.confirmation.yes',
    defaultMessage: 'Yes',
    description: 'Just Yes label',
  },
  vehicleWithoutDriverConfirmNo: {
    id: 'vehicle.no.driver.confirmation.no',
    defaultMessage: 'No',
    description: 'Just No label',
  },
  vehicleDifferentLicensePlatesConfirmNo: {
    id: 'vehicle.differentLicensePlate.cancel',
    defaultMessage: 'No',
    description: 'Just No label',
  },
  vehicleDifferentLicensePlatesConfirmYes: {
    id: 'vehicle.differentLicensePlate.confirm',
    defaultMessage: 'Yes',
    description: 'Just Yes label',
  },
  vehicleDifferentLicensePlatesConfirm: {
    id: 'vehicle.differentLicensePlate.text',
    defaultMessage:
      'Please check if the vehicle number are correct on both sails. Are you sure you want to save the changes?',
    description:
      'Message shown to the user to confirm that one wants to proceed to save changes even if license plates for round trip are different',
  },
  vehicleFailedToAllocateCustomWeight: {
    id: 'vehicle-custom-weight.failed.to.allocate',
    defaultMessage: 'Sorry! There is not enough space on the vessel',
    description: 'Failed to set new weight xx kg for the vehicle',
  },
  vehicleEstimatedPrice: {
    id: 'vehicle.estimated.price',
    defaultMessage: 'Estimated price of vehicle ticket',
    description: 'Estimated price of vehicle ticket',
  },
  reservationOnlySailHeader: {
    id: 'reservation.only.sail.header',
    defaultMessage: 'On this line it is only possible to make an online reservation.',
    description: 'On this line it is only possible to make an online reservation.',
  },
  reservationOnlySailText: {
    id: 'reservation.only.sail.text',
    defaultMessage:
      'Below ticket prices vary depending on the ticket type and now will not be included in the total payment. You need to pay a non-refundable reservation fee. Before paying a reservation fee please choose the ticket types and the number of passengers. Please note that you will need to purchase and get the required tickets separately from the ticket office in the harbour.',
    description: 'The freaking text',
  },
  reservationOnlySailCheckLabel: {
    id: 'reservation.only.sail.label',
    defaultMessage: 'Ok, I got it. Hide this message now.',
    description: 'Label to hide the message',
  },
  reservationOnlyFee: {
    id: 'reservation.only.fee',
    defaultMessage: 'Reservation fee TOTAL',
    description: 'Reservation fee TOTAL',
  },
  reservationOnlyFeeLaak: {
    id: 'reservation.only.fee-laak',
    defaultMessage: 'Reservation fee TOTAL',
    description: 'Reservation fee TOTAL',
  },
  reservationOnlyToPayLater: {
    id: 'reservation.only.to.pay.later',
    defaultMessage: 'Tickets cost (<b style="color: black">to be paid on board!</b>):',
    description: 'Tickets cost (to be paid on board!):',
  },
  bookingSucceeded: {
    id: 'booking-results.succeeded-label',
    defaultMessage: `Your payment succeeded, below you'll find your CONFIRMATION OF BOOKING`,
    description: 'Label for suceeded booking',
  },
  bookingFeeRow: {
    id: 'booking-results.booking-fee',
    defaultMessage: `Reservation fee via online booking`,
    description: 'Row to identify the online booking fee',
  },
  failToAllocateInventories: {
    id: 'main-inside.fail-to-allocate-inventories',
    defaultMessage: 'No tickets available. Please try again later or choose another trip!',
    description: 'No tickets available for all classes',
  },
  passengerInfoSaveNext: {
    id: 'manifest.button.save.next',
    defaultMessage: 'Save/Next',
    description: 'Save/Next',
  },
  editTicketTypePriceCanChange: {
    id: 'edit.ticket.type.price.can.change',
    defaultMessage: 'Note! The final price for ticket will be calculated only after clicking on the "Apply" button',
    description: 'Note! The final price for ticket will be calculated only after clicking on the "Apply" button',
  },
  editTicketCheckVehicleAdded: {
    id: 'edit.ticket.type.check.add.button.warning',
    defaultMessage: 'Make sure to press the add button before saving modification changes',
    description: 'Make sure to press the add button before saving modification changes',
  },
  verificationCode: {
    id: 'auth.smartid.verification.code',
    defaultMessage: 'Code: ',
    description: 'Code on the screen',
  },
  countryET: {
    id: 'auth.smartid.country.et',
    defaultMessage: 'Estonia',
    description: 'Estonia',
  },
  countryLV: {
    id: 'auth.smartid.country.lv',
    defaultMessage: 'Latvia',
    description: 'Latvia',
  },
  countryLT: {
    id: 'auth.smartid.country.lt',
    defaultMessage: 'Lithuania',
    description: 'Lithuania',
  },
  // GAVDOS
  portNameGavdos: {
    id: 'gavdos.port-name-gavdos',
    defaultMessage: 'Gavdos',
    description: 'Port name on the map',
  },
  portNameAgiaGalini: {
    id: 'gavdos.port-name-agiagalini',
    defaultMessage: 'AgiaGalini',
    description: 'Port name on the map',
  },
  portNamePlakias: {
    id: 'gavdos.port-name-plakias',
    defaultMessage: 'Plakias',
    description: 'Port name on the map',
  },
  chooseTicket: {
    id: 'common.choose-ticket',
    defaultMessage: 'Choose ticket type',
    description: 'Choose ticket type (adult, shoolchildren, etc)',
  },
  bookBike: {
    id: 'common.book-bike',
    defaultMessage: 'Book a bike',
    description: 'Book a bike',
  },
  gavdosTermsQue: {
    id: 'terms-and-conditions.gavdos-terms-question',
    defaultMessage: 'I agree with conditions of service:',
    description: 'Label for Terms title',
  },
  gavdosPaymentTerms: {
    id: 'payment-terms.gavdos-terms',
    defaultMessage:
      'This boarding pass has been issued from Island Eco Ferries Ltd to enable access to the ANENDYK Ferry, performing the relevant sail as mentioned on it.For any difficulties using this travel document kindly address any complaints to operations@gavdosdaycruise.com and we will get back to you soonest. This document may not be enough by itself for the voyage and ANENDYK has the right to issue ticket from their system and provide this to the passenger based on the confirmed online reservation',
    description: 'Terms for payment',
  },
  readMore: {
    id: 'common.read-more',
    defaultMessage: 'Read more',
    description: 'Button to expand text content',
  },
  readLess: {
    id: 'common.read-less',
    defaultMessage: 'Less',
    description: 'Button to hide expanded text content',
  },
  paymentPendingText: {
    id: 'payment.pending.text',
    defaultMessage:
      'Thank you for your purchase! Currently your payment is still processing. It may take some time for you to get the confirmation of payment. When payment is confirmed you will receive your tickets. Please stay on this page or leave it and wait for confirmation email.',
    description: 'Showing while trying to retry to load the ticket',
  },
  paymentPendingErrorText: {
    id: 'payment.pending.text.error',
    defaultMessage:
      'Sorry, we cannot proceed with your order at the moment, you can try to reload the page or wair for an email with you ticket',
    description: 'Showing to the user after all retries ',
  },
  paymentPendingHeading: {
    id: 'payment.pending.heading',
    defaultMessage: 'Stay tuned!',
    description: 'Showing the header',
  },
  paymentPendingButtonReload: {
    id: 'payment.pending.reload.button',
    defaultMessage: 'Reload the page',
    description: 'Button translation',
  },
  warningErrorCodePrefix: {
    id: 'warning.red.code.prefix',
    defaultMessage: 'Error code:',
    description: 'This will tell what is error code we show',
  },
  loginFailedUserNotValid: {
    id: 'auth.signin-failed-user-not-valid',
    defaultMessage: 'Email or password is incorrect',
    description: 'Email or password is incorrect',
  },
  loginFailedUserNotExisting: {
    id: 'auth.signin-failed-user-not-exists',
    defaultMessage: 'User {email} does not exist',
    description: 'User x does not exist',
  },
  residentDiscountPromotionText: {
    id: 'main-inside.local-traveller.discount.promotion',
    defaultMessage: 'Mark the ticket for a student in general secondary/vocational education',
    description: 'Mark the ticket for a student in general secondary/vocational education',
  },
  warningRouteErrorPrefix: {
    id: 'warning.on.line.prefix',
    defaultMessage: 'Warning on route {title}',
    description: 'Shows the upper warning bar message',
  },
})
